import * as Sentry from '@sentry/nextjs';

import { callQueries } from '@/utils/callQueries.helpers';
import { GetAllExternalSettingsDocument, GetUiSettingsDocument } from '@graphql/hooks-and-types';

export const getHomeSettings = async (clientName: string) => {
  try {
    const responses = await callQueries({
      queries: [{ query: GetAllExternalSettingsDocument, fetchPolicy: 'network-only' }],
      clientName,
    });
    return { settings: responses[0].data.getExternalSettings };
  } catch (error: any) {
    Sentry.captureException(error);
    return { settings: null };
  }
};

export const getUISettings = async (clientName: string) => {
  try {
    const responses = await callQueries({
      queries: [{ query: GetUiSettingsDocument, fetchPolicy: 'network-only' }],
      clientName,
    });
    return { UISettings: responses[0]?.data?.getUiSettings || [] };
  } catch (error: any) {
    Sentry.captureException(error);
    return { UISettings: [] };
  }
};
