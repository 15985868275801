import Image from 'next/image';
import React from 'react';

export interface InputPropsInterface {
  size?: 'small' | 'medium' | 'large';
}

const DynamicLoader = ({ size }: InputPropsInterface) => {
  switch (size) {
    case 'small':
      return <Image src="/pickspace-loader.gif" width="20" height="20" loading="lazy" alt="loader.." />;
    case 'medium':
      return <Image src="/pickspace-loader.gif" width="50" height="50" loading="lazy" alt="loader.." />;
    case 'large':
      return <Image src="/pickspace-loader.gif" width="100" height="100" loading="lazy" alt="loader.." />;
    default:
      return <Image src="/pickspace-loader.gif" width="50" height="50" loading="lazy" alt="loader.." />;
  }
};

export default DynamicLoader;
