import * as Sentry from '@sentry/nextjs';

export const getClientLocations = async (clientName) => {
  try {
    const response = await fetch(`https://${clientName}.pickspace.com/api/app/external-meetingroom?cmd=getLocations`);
    return response.json();
  } catch (error: any) {
    Sentry.captureException(error);
  }
};
