/* eslint-disable no-shadow */
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { ExternalSettingDto, ListingSettingDto, UiSettingsDto } from '@graphql/hooks-and-types';

export enum HomeSettingsTypeEnum {
  LOGO = 'external-logo',
  ALLOW_MEETING_ROOMS = 'meeting-rooms-box',
  MEETING_ROOM_HINT = 'meeting-rooms-price',
  ALLOW_DAY_PASS = 'day-pass-box',
  DAY_PASS_HINT = 'day-pass-price',
  ALLOW_UNITS = 'units-box',
  UNITS_HINT = 'units-price',
  ALLOW_PACKAGES = 'packages-box',
  PACKAGES_HINT = 'packages-price',
  ALLOW_TOURS = 'allow-tours-to-be-booked',
  SHOW_UNITS_LIST = 'allow-external-units',
  ABOUT_US = 'about-us',
  ABOUT_US_TITLE = 'about-us-title',
  ABOUT_US_IMAGE = 'about-us-background',
  FACEBOOK_LINK = 'facebook-link',
  TWITTER_LINK = 'twitter-link',
  INSTAGRAM_LINK = 'instagram-link',
  YOUTUBE_LINK = 'youtube-link',
}

type SettingsCard = ExternalSettingDto & { hint: string | null };

enum DayPassOptionEnum {
  FLEX = 'flex',
  RESERVED = 'reserved',
}

export enum SocialLinksAltTypes {
  FACEBOOK = 'facebook',
  LINKEDIN = 'linkedin',
  TWITTER = 'twitter',
}

type SettingsStore = {
  settings: ListingSettingDto | null;
  clientLogo: string | null;
  aboutUsImage: string | null;
  aboutUs: string | null;
  aboutUsTitle: string | null;
  meetingRoom: SettingsCard;
  dayPass: SettingsCard;
  packages: SettingsCard;
  units: SettingsCard;
  isMeetingRoomEnabled: boolean;
  isDayPassEnabled: boolean;
  isPackagesEnabled: boolean;
  isUnitsEnabled: boolean;
  isBookTourEnabled: boolean;
  dayPassOption: DayPassOptionEnum | null;
  clientLinks: {
    facebook: string;
    twitter: string;
    instagram: string;
    youtube: string;
    iosAppLink: string;
    androidAppLink: string;
  };
  setUISettings: (payload: { uiSettings: UiSettingsDto[] }) => void;
  setSettings: (payload: ListingSettingDto) => void;
  getSettings: () => ListingSettingDto | null;
  resetSettings: () => void;
};

const getHintValue = (hintData: any) => {
  return hintData?.enabled ? hintData.value : '';
};

export const useSettingsStore = create(
  persist<SettingsStore>(
    (set, get) => ({
      settings: null,
      clientLogo: null,
      aboutUs: null,
      aboutUsTitle: 'Where Living Meets Working',
      aboutUsImage: null,
      dayPass: null,
      meetingRoom: null,
      packages: null,
      units: null,
      isMeetingRoomEnabled: false,
      isDayPassEnabled: false,
      isPackagesEnabled: false,
      isUnitsEnabled: false,
      isBookTourEnabled: false,
      dayPassOption: null,
      clientLinks: {
        facebook: 'https://www.facebook.com/pickspace',
        twitter: 'https://twitter.com/pickspace',
        instagram: 'https://www.instagram.com/pickspace',
        youtube: 'https://www.youtube.com/pickspace',
        iosAppLink: 'https://apps.apple.com/us/app/pickspace/id1459155187?platform=iphone',
        androidAppLink: 'https://play.google.com/store/apps/details?id=org.spacebook.pickspacenew&pli=1',
      },
      setUISettings: ({ uiSettings }: { uiSettings: UiSettingsDto[] }) =>
        set((state) => {
          const mainColor = uiSettings.find((setting) => setting.slug === 'main_color')?.value;
          const secondaryColor = uiSettings.find((setting) => setting.slug === 'secondary_color')?.value;
          const errorColor = uiSettings.find((setting) => setting.slug === 'error_color')?.value;
          document.documentElement.style.setProperty('--main', mainColor);
          document.documentElement.style.setProperty('--primary', mainColor);
          document.documentElement.style.setProperty('--light-main', `${mainColor}80`);
          document.documentElement.style.setProperty('--secondary', secondaryColor);
          document.documentElement.style.setProperty('--light-secondary', `${secondaryColor}80`);
          document.documentElement.style.setProperty('--error', errorColor);
          document.documentElement.style.setProperty('--light-error', `${errorColor}80`);
          return state;
        }),
      setSettings: (payload: ListingSettingDto) =>
        set((state) => {
          state.settings = payload;
          state.dayPassOption = (payload.externalDaypassOption as DayPassOptionEnum) || null;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const settingsMap = new Map<string, any>();
          payload.externalSettings?.forEach((setting) => {
            settingsMap.set(setting.slug, setting);
          });
          const logoData = settingsMap.get(HomeSettingsTypeEnum.LOGO);
          state.clientLogo = logoData?.enabled ? logoData?.value || '' : '';
          state.isBookTourEnabled = settingsMap.get(HomeSettingsTypeEnum.ALLOW_TOURS)?.value === '1';
          state.aboutUs = settingsMap.get(HomeSettingsTypeEnum.ABOUT_US)?.value;
          state.aboutUsTitle = settingsMap.get(HomeSettingsTypeEnum.ABOUT_US_TITLE)?.value;
          state.aboutUsImage = settingsMap.get(HomeSettingsTypeEnum.ABOUT_US_IMAGE)?.value;
          state.dayPass = {
            ...(settingsMap.get(HomeSettingsTypeEnum.ALLOW_DAY_PASS) || {}),
            hint: getHintValue(settingsMap.get(HomeSettingsTypeEnum.DAY_PASS_HINT)),
          };
          state.meetingRoom = {
            ...(settingsMap.get(HomeSettingsTypeEnum.ALLOW_MEETING_ROOMS) || {}),
            hint: getHintValue(settingsMap.get(HomeSettingsTypeEnum.MEETING_ROOM_HINT)),
          };
          state.packages = {
            ...(settingsMap.get(HomeSettingsTypeEnum.ALLOW_PACKAGES) || {}),
            hint: getHintValue(settingsMap.get(HomeSettingsTypeEnum.PACKAGES_HINT)),
          };
          state.units = {
            ...(settingsMap.get(HomeSettingsTypeEnum.ALLOW_UNITS) || {}),
            hint: getHintValue(settingsMap.get(HomeSettingsTypeEnum.UNITS_HINT)),
          };
          state.isDayPassEnabled = state.dayPass.enabled;
          state.isMeetingRoomEnabled = state.meetingRoom.enabled;
          state.isPackagesEnabled = state.packages.enabled;
          state.isUnitsEnabled = state.units.enabled;
          state.clientLinks = {
            facebook: settingsMap.get(HomeSettingsTypeEnum.FACEBOOK_LINK)?.value || '',
            twitter: settingsMap.get(HomeSettingsTypeEnum.TWITTER_LINK)?.value || '',
            instagram: settingsMap.get(HomeSettingsTypeEnum.INSTAGRAM_LINK)?.value || '',
            youtube: settingsMap.get(HomeSettingsTypeEnum.YOUTUBE_LINK)?.value || '',
            iosAppLink: payload.mobileAppIosLink,
            androidAppLink: payload.mobileAppAndroidLink,
          };
          return state;
        }),
      getSettings: () => {
        const { settings } = get();
        return settings;
      },
      resetSettings: () =>
        set((state) => {
          state.settings = null;
          state.clientLogo = null;
          state.aboutUs = null;
          state.dayPass = null;
          state.meetingRoom = null;
          state.packages = null;
          state.units = null;
          state.isMeetingRoomEnabled = false;
          state.isDayPassEnabled = false;
          state.isPackagesEnabled = false;
          state.isUnitsEnabled = false;
          state.isBookTourEnabled = false;
          state.dayPassOption = null;
          return state;
        }),
    }),
    {
      name: 'settings',
      version: 1,
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
