import { default as packageInfo } from '../package.json';

export default {
  sentry: {
    dsn: process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN,
    debug: Boolean((process.env.SENTRY_DEBUG || process.env.NEXT_PUBLIC_SENTRY_DEBUG) === 'true'),
  },
  app: {
    url: String(process.env.APP_URL || process.env.NEXT_PUBLIC_APP_URL),
    name: String(process.env.APP_NAME || process.env.NEXT_PUBLIC_APP_NAME),
    version: String(packageInfo.version),
    env: process.env.APP_ENV || process.env.NEXT_PUBLIC_APP_ENV,
  },
  backend: {
    url: String(process.env.BACKEND_URL || process.env.NEXT_PUBLIC_BACKEND_URL),
    graphql: {
      url: String(process.env.BACKEND_GRAPHQL_URL || process.env.NEXT_PUBLIC_BACKEND_GRAPHQL_URL),
      subscription: String(
        process.env.BACKEND_GRAPHQL_SUBSCRIPTION_URL || process.env.NEXT_PUBLIC_BACKEND_GRAPHQL_SUBSCRIPTION_URL,
      ),
    },
    google: {
      map: {
        apiKey: String(process.env.GOOGLE_MAP_API_KEY || process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY),
      },
    },
  },
};
