import { ApolloLink, from, HttpLink, NormalizedCacheObject, split } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { deleteCookie, getCookie } from 'cookies-next';
import merge from 'deepmerge';
import { OperationDefinitionNode } from 'graphql';
import { createClient } from 'graphql-ws';
import isEqual from 'lodash.isequal';
import { useMemo } from 'react';

import config from '@config';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';
let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;
const httpLink = new HttpLink({
  uri: (context) => {
    if (context.getContext().url && config.app.env === 'production') {
      return context.getContext().url;
    }
    return String(config.backend.graphql.url);
  },

  credentials: 'same-origin',
});
const token = getCookie('token');

const wsLink =
  typeof window !== 'undefined'
    ? new GraphQLWsLink(
        createClient({
          url: config.backend.graphql.subscription,
          connectionParams: {
            authToken: token,
          },
        }),
      )
    : null;

const link =
  typeof window !== 'undefined'
    ? split(
        // only create the split in the browser
        // split based on operation type
        ({ query }) => {
          const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
          return kind === 'OperationDefinition' && operation === 'subscription';
        },
        wsLink as any,
        httpLink as any,
      )
    : httpLink;

// const cache = new InMemoryCache({}) as ApolloCache<NormalizedCacheObject>;

const authMiddleware = () =>
  new ApolloLink((operation, forward) => {
    if (token) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          'apollo-require-preflight': true,
          'content-type': 'application/json',
          authorization: token ? `Bearer ${token}` : undefined,
        },
      }));
    }

    return forward(operation);
  });

function createApolloClient() {
  return new ApolloClient({
    ssrMode: true,
    cache: new InMemoryCache({
      // just totally disable caching
      dataIdFromObject: () => null,
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    },
    link: from([
      authMiddleware(),
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
          );
        }
        if (networkError) {
          console.log(networkError);
          deleteCookie('token');
        }
      }),
      link as any,
    ]),
  });
}

export function addApolloState(client: ApolloClient<NormalizedCacheObject>, pageProps: any) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function initializeApollo(initialState: any = null) {
  const localApolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here

  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = localApolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    localApolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client

  if (typeof window === 'undefined') return localApolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = localApolloClient;

  return localApolloClient;
}

export function useApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  return useMemo(() => initializeApollo(state), [state]);
}
