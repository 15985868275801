import * as Sentry from '@sentry/nextjs';
import { DocumentNode } from 'graphql';

import { initializeApollo } from '@/lib/apollo';

export interface CallQueries {
  queries: { query: DocumentNode; data?: any }[];
  clientName: string;
}

export const callQueries = async ({ queries, clientName }) => {
  if (!clientName) {
    Sentry.captureMessage(`--------------call query failed--------------:`, {
      extra: {
        queries,
      },
    });
    return Promise.reject({ message: 'invalidClient' });
  }
  const client = initializeApollo();
  const queryPromises = queries.map((query) => {
    Sentry.captureMessage(`--------------query--------------:`, {
      extra: {
        query: {
          fetchPolicy: 'no-cache',
          context: {
            url: `https://${clientName}.pickspace.com/graphql/`,
            headers: {
              'apollo-require-preflight': true,
              'content-type': 'application/json',
            },
          },
          ...query,
        },
      },
    });
    return client.query({
      fetchPolicy: 'no-cache',
      context: {
        url: `https://${clientName}.pickspace.com/graphql/`,
        headers: {
          'apollo-require-preflight': true,
          'content-type': 'application/json',
        },
      },
      ...query,
    });
  });
  return Promise.all(queryPromises)
    .then((responses) => {
      Sentry.captureMessage(`--------------responses after promise all--------------:`, {
        extra: {
          responses,
        },
      });
      return responses;
    })
    .catch((error) => {
      Sentry.captureMessage('--------------responses after promise ERROR catch--------------', {
        extra: {
          error,
        },
      });
    });
};

export const propsErrorWrapper = (error: any, additionalProps?: any) => {
  const INVALID_CLIENT_NAME = 'You have to provide a valid client name that you want to grab its properties.';
  const NETWORK_ERROR = 'Oops it seems no internet connection please try again!';
  let serverError = 'Something went wrong. Please try again later.';
  Sentry.captureException(error);
  if (error.networkError) {
    serverError = NETWORK_ERROR;
  } else if (error.message.includes('invalidClient')) {
    serverError = INVALID_CLIENT_NAME;
  }
  return {
    props: {
      error: serverError,
      ...additionalProps,
    },
  };
};
