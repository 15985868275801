import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type AccountingAccountDto = {
  __typename?: 'AccountingAccountDTO';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AccountingAccountPaginator = {
  __typename?: 'AccountingAccountPaginator';
  data: Array<AccountingAccountDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type AccountingCodeDto = {
  __typename?: 'AccountingCodeDTO';
  accountingAccount: Scalars['Int']['output'];
  active?: Maybe<Scalars['Int']['output']>;
  camAllowed?: Maybe<Scalars['Int']['output']>;
  code: Scalars['String']['output'];
  codeType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  incomeAccountId: Scalars['Int']['output'];
  systemCode?: Maybe<Scalars['Int']['output']>;
};

export type AccountingCodePaginator = {
  __typename?: 'AccountingCodePaginator';
  data: Array<AccountingCodeDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type AggregatedAttribute = {
  aggregator: Scalars['String']['input'];
  alias?: InputMaybe<Scalars['String']['input']>;
  attribute: Scalars['String']['input'];
};

export enum AmenityColorsEnum {
  Blue = 'BLUE',
  Red = 'RED',
  Yellow = 'YELLOW',
}

export type AmenityDto = {
  __typename?: 'AmenityDTO';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: AmenityType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AmenityIconDto = {
  __typename?: 'AmenityIconDto';
  color?: Maybe<AmenityColorsEnum>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

/** Amenity type */
export enum AmenityType {
  Building = 'BUILDING',
  Unit = 'UNIT',
}

export type ApplicationDto = {
  __typename?: 'ApplicationDTO';
  approved: Scalars['Boolean']['output'];
  approverAdminId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  filledAt?: Maybe<Scalars['DateTime']['output']>;
  formTemplateId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  leadId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  officeId: Scalars['Int']['output'];
  rejectionReason?: Maybe<Scalars['String']['output']>;
  tourId?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type Attachment = {
  content: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type AvailableTypesDto = {
  __typename?: 'AvailableTypesDto';
  locationTypes: Array<Scalars['String']['output']>;
};

export type BasicAttributesDto = {
  __typename?: 'BasicAttributesDTO';
  icon: Scalars['String']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum BookableTypes {
  Broker = 'BROKER',
  Lead = 'LEAD',
  Member = 'MEMBER',
}

export type ChangePassword = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type CheckDto = {
  __typename?: 'CheckDTO';
  accountingCode?: Maybe<Scalars['String']['output']>;
  amount: Scalars['Int']['output'];
  bankAccountId?: Maybe<Scalars['String']['output']>;
  date: Scalars['String']['output'];
  expenseAccountId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  locationId?: Maybe<Scalars['Int']['output']>;
  membershipId?: Maybe<Scalars['Int']['output']>;
  memo?: Maybe<Scalars['String']['output']>;
  originalAmount?: Maybe<Scalars['Int']['output']>;
  payee?: Maybe<Scalars['String']['output']>;
  payeeName?: Maybe<Scalars['String']['output']>;
  reconciliationId?: Maybe<Scalars['Int']['output']>;
  selected?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  vendorId?: Maybe<Scalars['Int']['output']>;
};

export type CheckPaginator = {
  __typename?: 'CheckPaginator';
  data: Array<CheckDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type CompanyDto = {
  __typename?: 'CompanyDto';
  address: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CompanyPaginatorDto = {
  __typename?: 'CompanyPaginatorDto';
  data: Array<CompanyDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export enum ConditionOperator {
  And = 'AND',
  Or = 'OR',
}

export type ContentDto = {
  __typename?: 'ContentDTO';
  address?: Maybe<Scalars['String']['output']>;
  approvedAdminId?: Maybe<Scalars['Int']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  authorUser?: Maybe<UserDtoWithoutToken>;
  canGoIn: Scalars['Boolean']['output'];
  children: Array<ContentDto>;
  company?: Maybe<Scalars['String']['output']>;
  contactMail?: Maybe<Scalars['String']['output']>;
  contentBody?: Maybe<Scalars['String']['output']>;
  contentBodyImage?: Maybe<Scalars['String']['output']>;
  coverPhoto?: Maybe<Scalars['String']['output']>;
  coverPhoto2?: Maybe<Scalars['String']['output']>;
  coverPhoto3?: Maybe<Scalars['String']['output']>;
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  entryInstructions?: Maybe<Scalars['String']['output']>;
  escalationEmails?: Maybe<Scalars['String']['output']>;
  eventDate?: Maybe<Scalars['String']['output']>;
  eventEndDate?: Maybe<Scalars['String']['output']>;
  hiddenFromMembers: Scalars['Boolean']['output'];
  hire?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isApproved?: Maybe<Scalars['Int']['output']>;
  location: LocationEntity;
  locationId?: Maybe<Scalars['Int']['output']>;
  maxrsvp?: Maybe<Scalars['Int']['output']>;
  member?: Maybe<MemberDto>;
  memberId?: Maybe<Scalars['Int']['output']>;
  office?: Maybe<OfficeDto>;
  officeId?: Maybe<Scalars['Int']['output']>;
  parent?: Maybe<Scalars['String']['output']>;
  photoCsv?: Maybe<Scalars['String']['output']>;
  pinned?: Maybe<Scalars['String']['output']>;
  plusone?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  priorty?: Maybe<Scalars['String']['output']>;
  rejectionReason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subStatuses?: Maybe<Scalars['String']['output']>;
  subType?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserDtoWithoutToken>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type ContentPaginator = {
  __typename?: 'ContentPaginator';
  data: Array<ContentDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export enum ContentTypes {
  BrokerDiscussion = 'BROKER_DISCUSSION',
  BrokerDiscussionReply = 'BROKER_DISCUSSION_REPLY',
  Event = 'EVENT',
  Gallery = 'GALLERY',
  Job = 'JOB',
  LegalcaseReply = 'LEGALCASE_REPLY',
  News = 'NEWS',
  Offer = 'OFFER',
  Post = 'POST',
  Sale = 'SALE',
  Ticket = 'TICKET',
  TicketReply = 'TICKET_REPLY',
}

export type ContractDto = {
  __typename?: 'ContractDTO';
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  contractTemplateId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fileId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isSigned?: Maybe<Scalars['Boolean']['output']>;
  memberId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  signId?: Maybe<Scalars['Int']['output']>;
  signUrl?: Maybe<Scalars['String']['output']>;
  signedAt?: Maybe<Scalars['DateTime']['output']>;
  signedName?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status: ContractStatuses;
  url?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserDtoWithoutToken>;
  userId?: Maybe<Scalars['Float']['output']>;
};

export type ContractPaginator = {
  __typename?: 'ContractPaginator';
  data: Array<ContractDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type ContractTemplateDto = {
  __typename?: 'ContractTemplateDTO';
  contractType: ContractTypes;
  fileId?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  localFileName?: Maybe<Scalars['String']['output']>;
  localFilePath?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

export type ContractTemplatePaginator = {
  __typename?: 'ContractTemplatePaginator';
  data: Array<ContractTemplateDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type CountryDto = {
  __typename?: 'CountryDTO';
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type CountryPaginator = {
  __typename?: 'CountryPaginator';
  data: Array<CountryDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type CreateAccountingAccount = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateApplicationValidation = {
  formTemplateId: Scalars['Int']['input'];
  leadId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  officeId: Scalars['Int']['input'];
  tourId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateCheck = {
  accountingCode: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
  bankAccountId: Scalars['Float']['input'];
  date: Scalars['DateTime']['input'];
  expenseAccountId: Scalars['Float']['input'];
  membershipId: Scalars['Float']['input'];
  originalAmount: Scalars['Float']['input'];
  payeeName: Scalars['String']['input'];
  vendorId: Scalars['Float']['input'];
};

export type CreateCompanyValidation = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type CreateContent = {
  address?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  contactMail?: InputMaybe<Scalars['String']['input']>;
  contentBody?: InputMaybe<Scalars['String']['input']>;
  contentBodyImage?: InputMaybe<Scalars['String']['input']>;
  coverPhoto?: InputMaybe<Scalars['String']['input']>;
  dateCreated?: InputMaybe<Scalars['DateTime']['input']>;
  eventDate?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['Upload']['input']>;
  parent?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: ContentTypes;
};

export type CreateContractOfficeMeta = {
  memberEndDate: Scalars['DateTime']['input'];
};

export type CreateContractTemplate = {
  contractType: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateCustomFieldValueValidation = {
  applicationId: Scalars['Int']['input'];
  customFieldId: Scalars['Int']['input'];
  formTemplateId: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

export type CreateEmailGroup = {
  conditionsOperator: ConditionOperator;
  name: Scalars['String']['input'];
};

export type CreateEmailGroupCondition = {
  columnName: Scalars['String']['input'];
  emailGroupId: Scalars['Int']['input'];
  operator: EmailGroupConditionOperators;
  value: Scalars['String']['input'];
};

export type CreateEmailListValidation = {
  email: Scalars['String']['input'];
  emailSenderId: Scalars['Float']['input'];
  locationId: Scalars['Float']['input'];
};

export type CreateEmailSender = {
  email: Scalars['String']['input'];
  emailListLocations: Array<CreateEmailListValidation>;
  isAccounts?: Scalars['Boolean']['input'];
  isContactus?: Scalars['Boolean']['input'];
  isList?: Scalars['Boolean']['input'];
  isSystem?: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type CreateEmailTemplate = {
  body: Scalars['String']['input'];
  name: Scalars['String']['input'];
  recipient: Scalars['String']['input'];
  senderId: Scalars['Int']['input'];
  subject: Scalars['String']['input'];
};

export type CreateExpense = {
  adminFee?: InputMaybe<Scalars['Int']['input']>;
  amount: Scalars['Float']['input'];
  camId?: InputMaybe<Scalars['Int']['input']>;
  capAmount?: InputMaybe<Scalars['Int']['input']>;
  capped?: InputMaybe<Scalars['Int']['input']>;
  date: Scalars['DateTime']['input'];
  description: Scalars['String']['input'];
  expenseAccountId: Scalars['Float']['input'];
  isCam: Scalars['Float']['input'];
  locationId: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  shareRatio?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  typeId: Scalars['Float']['input'];
};

export type CreateExpenseType = {
  accountingAccountsId: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateInspection = {
  inspectionDate: Scalars['DateTime']['input'];
  locationId?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['Int']['input']>;
  reminderEmails?: InputMaybe<Scalars['String']['input']>;
  reminderTime?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<InspectionStatus>;
  vendorId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateInvoice = {
  paymentRequired: Scalars['String']['input'];
};

export type CreateJournalEntry = {
  date: Scalars['String']['input'];
  journalNo: Scalars['String']['input'];
  records: Array<CreateJournalEntryRecord>;
};

export type CreateJournalEntryRecord = {
  accountId: Scalars['Int']['input'];
  credit: Scalars['Int']['input'];
  debit: Scalars['Float']['input'];
  description: Scalars['String']['input'];
  journalId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateLeadValidation = {
  brokerId?: InputMaybe<Scalars['Int']['input']>;
  creatorId?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  leadSourceId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  pipeLineStageId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateLocation = {
  accounts: Array<CreateLocationTransactionAccount>;
  addVatAutomatically?: InputMaybe<Scalars['Boolean']['input']>;
  address: Scalars['String']['input'];
  bankAccountId?: InputMaybe<Scalars['Int']['input']>;
  city: Scalars['String']['input'];
  code?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['Int']['input'];
  currencyId: Scalars['Int']['input'];
  disableAutoCharge?: InputMaybe<Scalars['Int']['input']>;
  ein?: InputMaybe<Scalars['String']['input']>;
  estDate: Scalars['String']['input'];
  legalEntity?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  supportEmails?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  vat?: InputMaybe<Scalars['Int']['input']>;
  zoneId: Scalars['Int']['input'];
};

export type CreateLocationTransactionAccount = {
  locationId?: InputMaybe<Scalars['Int']['input']>;
  transactionAccountId: Scalars['Int']['input'];
  transactionAccountQuestionId: Scalars['Int']['input'];
};

export type CreateMember = {
  additionalEmails?: InputMaybe<Scalars['String']['input']>;
  address: Scalars['String']['input'];
  bankAccountId?: InputMaybe<Scalars['Int']['input']>;
  company: Scalars['String']['input'];
  customCurrency?: InputMaybe<Scalars['String']['input']>;
  customCurrencyAuto?: InputMaybe<Scalars['String']['input']>;
  customCurrencyOn?: InputMaybe<Scalars['String']['input']>;
  customCurrencyRate?: InputMaybe<Scalars['String']['input']>;
  dob?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  industryId?: InputMaybe<Scalars['Int']['input']>;
  isExternal?: InputMaybe<Scalars['Int']['input']>;
  keyCardsGranted?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['Int']['input']>;
  locations: Array<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  paymentLocationid?: InputMaybe<Scalars['Int']['input']>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  sapFederalTaxId?: InputMaybe<Scalars['String']['input']>;
  setupFee?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMemberLogInvoice = {
  chargeDateType: Scalars['String']['input'];
  chargeDateValue: Scalars['String']['input'];
  memberId: Scalars['Float']['input'];
};

export type CreateOffice = {
  accountingCode?: InputMaybe<Scalars['String']['input']>;
  approxValue?: InputMaybe<Scalars['String']['input']>;
  availableDate?: InputMaybe<Scalars['String']['input']>;
  baseRate: Scalars['String']['input'];
  bathrooms?: InputMaybe<Scalars['String']['input']>;
  bedrooms?: InputMaybe<Scalars['String']['input']>;
  curRate?: InputMaybe<Scalars['String']['input']>;
  discountRate?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['String']['input']>;
  kisiGroup?: InputMaybe<Scalars['String']['input']>;
  leaveReason?: InputMaybe<Scalars['String']['input']>;
  locationid: Scalars['Int']['input'];
  marketingText?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  numOfBWPrints: Scalars['String']['input'];
  numOfColorPrints: Scalars['String']['input'];
  numOfConfRoomHours: Scalars['String']['input'];
  numOfTerms?: InputMaybe<Scalars['String']['input']>;
  parkingNumber?: InputMaybe<Scalars['String']['input']>;
  postBoxNumber?: InputMaybe<Scalars['String']['input']>;
  seats: Scalars['String']['input'];
  sqft?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type CreateOfficeNote = {
  adminId: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  officeId: Scalars['Int']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOutgoingPayment = {
  amount: Scalars['Int']['input'];
  billBankAccountId?: InputMaybe<Scalars['String']['input']>;
  billErrorMessage?: InputMaybe<Scalars['String']['input']>;
  billPaymentId?: InputMaybe<Scalars['String']['input']>;
  checkId: Scalars['Int']['input'];
  expenseAccountId: Scalars['Int']['input'];
  invoiceId: Scalars['Int']['input'];
  isBillAdded?: InputMaybe<Scalars['Int']['input']>;
  paymentMethod: Scalars['String']['input'];
  processDate: Scalars['String']['input'];
  type: Scalars['String']['input'];
  vendorId: Scalars['Int']['input'];
};

export type CreatePackage = {
  delieveredAt: Scalars['DateTime']['input'];
  deliveryService?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['Int']['input'];
  memberId: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  pickedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pickedBy?: InputMaybe<Scalars['String']['input']>;
  serial?: InputMaybe<Scalars['Int']['input']>;
  status?: PackageStatuses;
  type: Types;
};

export type CreatePayment = {
  amount: Scalars['Float']['input'];
  date: Scalars['DateTime']['input'];
  deduction?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  incomeAccountId: Scalars['Float']['input'];
  invoiceId?: InputMaybe<Scalars['Float']['input']>;
  memberId?: InputMaybe<Scalars['Float']['input']>;
  paymentMethod: Scalars['String']['input'];
  paymentType: Scalars['String']['input'];
};

export type CreatePaymentBatch = {
  date: Scalars['String']['input'];
  description: Scalars['String']['input'];
  fromTransactionAccountId: Scalars['Int']['input'];
  isPaymentProcessorBatch: Scalars['Boolean']['input'];
  paymentCorrelationId?: InputMaybe<Scalars['Int']['input']>;
  paymentIds: Array<Scalars['Int']['input']>;
  paymentProcessorType?: InputMaybe<Scalars['String']['input']>;
  toTransactionAccountId: Scalars['Int']['input'];
};

export type CreatePushToken = {
  deviceOs: DeviceOs;
  token: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type CreateReconciliation = {
  difference: Scalars['Int']['input'];
  endBalance: Scalars['Int']['input'];
  endDate: Scalars['String']['input'];
  startBalance: Scalars['Int']['input'];
  startDate: Scalars['String']['input'];
  transactionAccountId: Scalars['Int']['input'];
};

export type CreateScheduledIncrease = {
  applyDate: Scalars['DateTime']['input'];
  locationId: Scalars['Int']['input'];
  newRate: Scalars['String']['input'];
  officeId?: InputMaybe<Scalars['Int']['input']>;
  serviceId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSmartContract = {
  contract_template_id: Scalars['Int']['input'];
  member: CreateMember;
  name: Scalars['String']['input'];
  offices: Array<CreateContractOfficeMeta>;
  startDate: Scalars['DateTime']['input'];
  userId: Scalars['Float']['input'];
};

export type CreateTour = {
  bookableId?: InputMaybe<Scalars['Int']['input']>;
  bookableType: BookableTypes;
  bookedAt: Scalars['DateTime']['input'];
  lead: CreateLeadValidation;
  locationId: Scalars['Int']['input'];
  notes: Scalars['String']['input'];
  officeId?: InputMaybe<Scalars['Int']['input']>;
  visitedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateTransactionAccount = {
  accountName: Scalars['String']['input'];
  accountNumber: Scalars['String']['input'];
  accountTypeId: Scalars['Int']['input'];
  bankAddress: Scalars['String']['input'];
  bankCity: Scalars['String']['input'];
  bankName: Scalars['String']['input'];
  bankPhone: Scalars['String']['input'];
  currencyId: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  fromAddress: Scalars['String']['input'];
  fromCity: Scalars['String']['input'];
  fromName: Scalars['String']['input'];
  locationId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  orderNumber: Scalars['Int']['input'];
  parentId?: InputMaybe<Scalars['Int']['input']>;
  routingNumber: Scalars['String']['input'];
  startingBalance: Scalars['Float']['input'];
  status?: Scalars['String']['input'];
  subAccountTypeId: Scalars['Int']['input'];
  type: Scalars['String']['input'];
};

export type CreateTransactionAccountQuestion = {
  name: Scalars['String']['input'];
};

export type CreateVendor = {
  accountNum?: InputMaybe<Scalars['String']['input']>;
  accountingCode?: InputMaybe<Scalars['String']['input']>;
  address: Scalars['String']['input'];
  bankAddress?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  brokerlicence?: InputMaybe<Scalars['String']['input']>;
  commission?: InputMaybe<Scalars['Int']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  durationType?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  office?: InputMaybe<Scalars['String']['input']>;
  paymentMethod: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  routingNum?: InputMaybe<Scalars['String']['input']>;
  swiftcode?: InputMaybe<Scalars['String']['input']>;
  taxId: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CurrencyDto = {
  __typename?: 'CurrencyDTO';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nameAlt?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  symbol: Scalars['String']['output'];
};

export type CurrencyPaginator = {
  __typename?: 'CurrencyPaginator';
  data: Array<CurrencyDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type CustomFieldDto = {
  __typename?: 'CustomFieldDTO';
  defaultOptions: Array<DefaultSelectOption>;
  defaultValue?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  placeholder?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  type: CustomerFieldModels;
};

export type CustomFieldValueDto = {
  __typename?: 'CustomFieldValueDTO';
  applicationId: Scalars['Int']['output'];
  customFieldId: Scalars['Int']['output'];
  formTemplateId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type DeclineSmartContract = {
  contractId: Scalars['Int']['input'];
  rejectionReason: Scalars['String']['input'];
};

export type DefaultSelectOption = {
  __typename?: 'DefaultSelectOption';
  displayableValue: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum DeviceOs {
  Android = 'android',
  Ios = 'ios',
}

export type DynamicFilter = {
  exact: Scalars['Boolean']['input'];
  id: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

export type EmailGroupConditionDto = {
  __typename?: 'EmailGroupConditionDTO';
  columnName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  emailGroupId: Scalars['Int']['output'];
  group: EmailGroupDto;
  id: Scalars['Int']['output'];
  operator: EmailGroupConditionOperators;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  value: Scalars['String']['output'];
};

export enum EmailGroupConditionOperators {
  Equals = 'equals',
  GreaterThan = 'greater_than',
  LessThan = 'less_than',
  NotEqual = 'not_equal',
}

export type EmailGroupConditionPaginator = {
  __typename?: 'EmailGroupConditionPaginator';
  data: Array<EmailGroupConditionDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type EmailGroupDto = {
  __typename?: 'EmailGroupDTO';
  conditions: Array<EmailGroupDto>;
  conditionsOperator: ConditionOperator;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type EmailGroupPaginator = {
  __typename?: 'EmailGroupPaginator';
  data: Array<EmailGroupDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type EmailSenderDto = {
  __typename?: 'EmailSenderDTO';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  emailTemplates: Array<EmailTemplateEntity>;
  id: Scalars['Int']['output'];
  isAccounts: Scalars['Boolean']['output'];
  isContactus: Scalars['Boolean']['output'];
  isList?: Maybe<Scalars['Boolean']['output']>;
  isSystem: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EmailSenderEntity = {
  __typename?: 'EmailSenderEntity';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  emailTemplates: Array<EmailTemplateEntity>;
  id: Scalars['Int']['output'];
  isAccounts: Scalars['Boolean']['output'];
  isContactus: Scalars['Boolean']['output'];
  isList?: Maybe<Scalars['Boolean']['output']>;
  isSystem: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EmailSenderPaginator = {
  __typename?: 'EmailSenderPaginator';
  data: Array<EmailSenderDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type EmailTemplateDto = {
  __typename?: 'EmailTemplateDTO';
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<Scalars['String']['output']>;
  sender: EmailSenderDto;
  senderId?: Maybe<Scalars['Int']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EmailTemplateEntity = {
  __typename?: 'EmailTemplateEntity';
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<Scalars['String']['output']>;
  senderId?: Maybe<Scalars['Int']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EmailTemplatePagintor = {
  __typename?: 'EmailTemplatePagintor';
  data: Array<EmailTemplateDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type EmailVariableDto = {
  __typename?: 'EmailVariableDTO';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  isMember?: Maybe<Scalars['Boolean']['output']>;
  isUser?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type EmailVariablePaginator = {
  __typename?: 'EmailVariablePaginator';
  data: Array<EmailVariableDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type ExpenseDto = {
  __typename?: 'ExpenseDTO';
  adminFee?: Maybe<Scalars['Int']['output']>;
  amount: Scalars['Float']['output'];
  authorizerId: Scalars['Int']['output'];
  camId?: Maybe<Scalars['Int']['output']>;
  capAmount?: Maybe<Scalars['Int']['output']>;
  capped?: Maybe<Scalars['Int']['output']>;
  date: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  expenseAccountId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isCam?: Maybe<Scalars['Int']['output']>;
  locationId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  shareRatio?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  typeId: Scalars['Int']['output'];
};

export type ExpensePaginator = {
  __typename?: 'ExpensePaginator';
  data: Array<ExpenseDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type ExpenseTypeDto = {
  __typename?: 'ExpenseTypeDTO';
  accountingAccountsId: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ExpenseTypePaginator = {
  __typename?: 'ExpenseTypePaginator';
  data: Array<ExpenseTypeDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type ExternalAmenityDto = {
  __typename?: 'ExternalAmenityDto';
  color?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<AmenityIconDto>;
  iconId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type ExternalSettingDto = {
  __typename?: 'ExternalSettingDto';
  description?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Filter = {
  column: Scalars['String']['input'];
  operator: Operator;
  value: Scalars['String']['input'];
};

export type Filterable = {
  filter?: InputMaybe<Array<Schema>>;
  sort?: InputMaybe<Array<Sort>>;
};

export type FindFormTemplateValidation = {
  id: Scalars['Int']['input'];
};

export type FormTemplateDto = {
  __typename?: 'FormTemplateDTO';
  fields: Array<FormTemplateFieldEntity>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type FormTemplateFieldDto = {
  __typename?: 'FormTemplateFieldDTO';
  conditionalValue?: Maybe<Scalars['String']['output']>;
  customFieldId: Scalars['Int']['output'];
  formTemplateId: Scalars['Int']['output'];
  group: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  multi: Scalars['Boolean']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['Int']['output']>;
  refs?: Maybe<Array<Scalars['String']['output']>>;
};

export type FormTemplateFieldEntity = {
  __typename?: 'FormTemplateFieldEntity';
  conditionalValue?: Maybe<Scalars['String']['output']>;
  customFieldId: Scalars['Int']['output'];
  formTemplateId: Scalars['Int']['output'];
  group: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  multi: Scalars['Boolean']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['Int']['output']>;
  refs?: Maybe<Array<Scalars['String']['output']>>;
};

export type GeoJson = {
  __typename?: 'GeoJSON';
  coordinates: Array<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
};

export type GetAllLocks = {
  dpuId?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  lineId?: InputMaybe<Scalars['Int']['input']>;
};

export type GetAmenityByTypeValidation = {
  type: AmenityType;
};

export type GetCurrencyByCode = {
  code: Scalars['String']['input'];
};

export type GetCurrencyById = {
  id: Scalars['Int']['input'];
};

export type GetLocksDto = {
  __typename?: 'GetLocksDto';
  id: Scalars['Int']['output'];
  lock: LockDto;
  name: Scalars['String']['output'];
};

export type GetOfficeFiltersValidation = {
  terminology: Scalars['String']['input'];
};

export type GetOfficeHistoriesByOfficeId = {
  officeId: Scalars['Int']['input'];
  year?: Scalars['Int']['input'];
};

export type GetOfficeListingByIdValidation = {
  id: Scalars['Int']['input'];
};

export type GetOfficeReport = {
  locationId: Scalars['Int']['input'];
  officeId: Scalars['Int']['input'];
  year?: Scalars['Int']['input'];
};

export type GetOfficesListing = {
  dynamicFilter?: InputMaybe<Array<DynamicFilter>>;
  staticFilter?: InputMaybe<StaticFilter>;
};

export type GetPaymentLocationDetailsForMember = {
  locationId: Scalars['Int']['input'];
  memberId?: InputMaybe<Scalars['Int']['input']>;
};

export type GetUiSettingsDto = {
  __typename?: 'GetUiSettingsDTO';
  uiSettings: Array<UiSettingsDto>;
};

export type Includeable = {
  aggregatedAttributes?: Array<AggregatedAttribute>;
  association: Scalars['String']['input'];
  attributes?: Array<Scalars['String']['input']>;
  filter?: InputMaybe<Array<Schema>>;
  includes?: InputMaybe<Array<Includeable>>;
  innerJoin?: Scalars['Boolean']['input'];
  subquery?: Scalars['Boolean']['input'];
};

export type Includeables = {
  includes?: InputMaybe<Array<Includeable>>;
};

export type InspectionDto = {
  __typename?: 'InspectionDTO';
  adminId?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  inspectionDate: Scalars['DateTime']['output'];
  locationId?: Maybe<Scalars['Float']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  officeId?: Maybe<Scalars['Float']['output']>;
  reminderEmails?: Maybe<Scalars['String']['output']>;
  reminderTime?: Maybe<Scalars['DateTime']['output']>;
  status: InspectionStatus;
  vendorId?: Maybe<Scalars['Float']['output']>;
};

export type InspectionPaginator = {
  __typename?: 'InspectionPaginator';
  data: Array<InspectionDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export enum InspectionStatus {
  Accepted = 'ACCEPTED',
  Inprogress = 'INPROGRESS',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export type InvoiceDto = {
  __typename?: 'InvoiceDTO';
  accountingCode?: Maybe<Scalars['String']['output']>;
  authId?: Maybe<Scalars['Int']['output']>;
  authToken?: Maybe<Scalars['String']['output']>;
  baseRate?: Maybe<Scalars['String']['output']>;
  billApproved?: Maybe<Scalars['String']['output']>;
  billErrorMessage?: Maybe<Scalars['String']['output']>;
  billInvoiceId?: Maybe<Scalars['String']['output']>;
  billNumber?: Maybe<Scalars['String']['output']>;
  billVendorId?: Maybe<Scalars['String']['output']>;
  billingEndDate?: Maybe<Scalars['String']['output']>;
  camId?: Maybe<Scalars['Int']['output']>;
  chargedLocationid?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  cronProcessedAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  discountPercent?: Maybe<Scalars['String']['output']>;
  endDay?: Maybe<Scalars['String']['output']>;
  greenInvoiceErrorMessage?: Maybe<Scalars['String']['output']>;
  greenInvoiceId?: Maybe<Scalars['String']['output']>;
  greenInvoiceNumber?: Maybe<Scalars['String']['output']>;
  greenInvoicePdfEn?: Maybe<Scalars['String']['output']>;
  greenInvoicePdfHe?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isAutoChargeDisabled?: Maybe<Scalars['Int']['output']>;
  isBillAdded?: Maybe<Scalars['Int']['output']>;
  isCronProcessed?: Maybe<Scalars['Int']['output']>;
  isGreenInvoiceAdded?: Maybe<Scalars['Int']['output']>;
  isLateFeeEmailSent?: Maybe<Scalars['Int']['output']>;
  isQuickBookAdded?: Maybe<Scalars['Int']['output']>;
  isSapAdded?: Maybe<Scalars['Int']['output']>;
  isXeroAdded?: Maybe<Scalars['Int']['output']>;
  locationid?: Maybe<Scalars['Int']['output']>;
  mailed?: Maybe<Scalars['String']['output']>;
  member?: Maybe<MemberDto>;
  memberid?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  officeid?: Maybe<Scalars['String']['output']>;
  paidDate?: Maybe<Scalars['DateTime']['output']>;
  paymentDate?: Maybe<Scalars['String']['output']>;
  paymentDue?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  paymentPaid?: Maybe<Scalars['String']['output']>;
  paymentRequired?: Maybe<Scalars['String']['output']>;
  quickBookErrorMessage?: Maybe<Scalars['String']['output']>;
  quickBookInvoiceId?: Maybe<Scalars['String']['output']>;
  quickBookInvoiceLastUpdate?: Maybe<Scalars['DateTime']['output']>;
  quickBookPaymentId?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Scalars['String']['output']>;
  recurringId?: Maybe<Scalars['ID']['output']>;
  referralId?: Maybe<Scalars['Int']['output']>;
  sapErrorMessage?: Maybe<Scalars['String']['output']>;
  sapInvoiceId?: Maybe<Scalars['Int']['output']>;
  serviceRetainerPaid?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  vat?: Maybe<Scalars['Int']['output']>;
  vendorLocationId?: Maybe<Scalars['Int']['output']>;
  viewDate?: Maybe<Scalars['String']['output']>;
  xeroErrorMessage?: Maybe<Scalars['String']['output']>;
  xeroInvoiceId?: Maybe<Scalars['String']['output']>;
  xeroPaymentId?: Maybe<Scalars['String']['output']>;
};

export type InvoiceEntity = {
  __typename?: 'InvoiceEntity';
  accountingCode?: Maybe<Scalars['String']['output']>;
  authId?: Maybe<Scalars['Int']['output']>;
  authToken?: Maybe<Scalars['String']['output']>;
  baseRate?: Maybe<Scalars['String']['output']>;
  billApproved?: Maybe<Scalars['String']['output']>;
  billErrorMessage?: Maybe<Scalars['String']['output']>;
  billInvoiceId?: Maybe<Scalars['String']['output']>;
  billNumber?: Maybe<Scalars['String']['output']>;
  billVendorId?: Maybe<Scalars['String']['output']>;
  billingEndDate?: Maybe<Scalars['String']['output']>;
  camId?: Maybe<Scalars['Int']['output']>;
  chargedLocationid?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  cronProcessedAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  discountPercent?: Maybe<Scalars['String']['output']>;
  endDay?: Maybe<Scalars['String']['output']>;
  greenInvoiceErrorMessage?: Maybe<Scalars['String']['output']>;
  greenInvoiceId?: Maybe<Scalars['String']['output']>;
  greenInvoiceNumber?: Maybe<Scalars['String']['output']>;
  greenInvoicePdfEn?: Maybe<Scalars['String']['output']>;
  greenInvoicePdfHe?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isAutoChargeDisabled?: Maybe<Scalars['Int']['output']>;
  isBillAdded?: Maybe<Scalars['Int']['output']>;
  isCronProcessed?: Maybe<Scalars['Int']['output']>;
  isGreenInvoiceAdded?: Maybe<Scalars['Int']['output']>;
  isLateFeeEmailSent?: Maybe<Scalars['Int']['output']>;
  isQuickBookAdded?: Maybe<Scalars['Int']['output']>;
  isSapAdded?: Maybe<Scalars['Int']['output']>;
  isXeroAdded?: Maybe<Scalars['Int']['output']>;
  locationid?: Maybe<Scalars['Int']['output']>;
  mailed?: Maybe<Scalars['String']['output']>;
  memberid?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  officeid?: Maybe<Scalars['String']['output']>;
  paidDate?: Maybe<Scalars['DateTime']['output']>;
  paymentDate?: Maybe<Scalars['String']['output']>;
  paymentDue?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  paymentPaid?: Maybe<Scalars['String']['output']>;
  paymentRequired?: Maybe<Scalars['String']['output']>;
  quickBookErrorMessage?: Maybe<Scalars['String']['output']>;
  quickBookInvoiceId?: Maybe<Scalars['String']['output']>;
  quickBookInvoiceLastUpdate?: Maybe<Scalars['DateTime']['output']>;
  quickBookPaymentId?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Scalars['String']['output']>;
  recurringId?: Maybe<Scalars['ID']['output']>;
  referralId?: Maybe<Scalars['Int']['output']>;
  sapErrorMessage?: Maybe<Scalars['String']['output']>;
  sapInvoiceId?: Maybe<Scalars['Int']['output']>;
  serviceRetainerPaid?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  vat?: Maybe<Scalars['Int']['output']>;
  vendorLocationId?: Maybe<Scalars['Int']['output']>;
  viewDate?: Maybe<Scalars['String']['output']>;
  xeroErrorMessage?: Maybe<Scalars['String']['output']>;
  xeroInvoiceId?: Maybe<Scalars['String']['output']>;
  xeroPaymentId?: Maybe<Scalars['String']['output']>;
};

export type InvoicePaginator = {
  __typename?: 'InvoicePaginator';
  data: Array<InvoiceDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type IsOfficeDuplicated = {
  locationId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type JournalEntryDto = {
  __typename?: 'JournalEntryDTO';
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  journalNo: Scalars['String']['output'];
  locationId: Scalars['String']['output'];
  remarks: Scalars['String']['output'];
  reversalDate: Scalars['String']['output'];
};

export type JournalEntryPaginator = {
  __typename?: 'JournalEntryPaginator';
  data: Array<JournalEntryDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type LeadDto = {
  __typename?: 'LeadDTO';
  brokerId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creatorId?: Maybe<Scalars['Int']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  isConverted: Scalars['Boolean']['output'];
  leadSourceId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  pipeLineStageId?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type LeadSourceDto = {
  __typename?: 'LeadSourceDTO';
  id: Scalars['ID']['output'];
};

export type LeadSourcePaginator = {
  __typename?: 'LeadSourcePaginator';
  data: Array<LeadSourceDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type ListingMedia = {
  __typename?: 'ListingMedia';
  name: Scalars['String']['output'];
  status: MediaStatus;
  url: Scalars['String']['output'];
};

export type ListingSectionItemLinksDto = {
  __typename?: 'ListingSectionItemLinksDTO';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  teamMemberId: Scalars['Float']['output'];
};

export type ListingSectionItemsDto = {
  __typename?: 'ListingSectionItemsDTO';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageLink?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  links?: Maybe<Array<ListingSectionItemLinksDto>>;
  name?: Maybe<Scalars['String']['output']>;
  sectionKey: Scalars['String']['output'];
};

export type ListingSectionsDto = {
  __typename?: 'ListingSectionsDTO';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  items: Array<ListingSectionItemsDto>;
  slug: Scalars['String']['output'];
  subTitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ListingSettingDto = {
  __typename?: 'ListingSettingDTO';
  allowExternalDayPass: Scalars['Boolean']['output'];
  allowExternalMeetingRooms: Scalars['Boolean']['output'];
  allowExternalPackage: Scalars['Boolean']['output'];
  externalDaypassOption?: Maybe<Scalars['String']['output']>;
  externalSettings: Array<ExternalSettingDto>;
  isListingEnabled: Scalars['Boolean']['output'];
  mobileAppAndroidLink?: Maybe<Scalars['String']['output']>;
  mobileAppIosLink?: Maybe<Scalars['String']['output']>;
};

export type LocationCountStatisticsDto = {
  __typename?: 'LocationCountStatisticsDTO';
  nextMonthCount: LocationVacancyAndOccupiation;
  nextMonthLastYearCount: LocationVacancyAndOccupiation;
  nextYearCount: LocationVacancyAndOccupiation;
  previousMonthCount: LocationVacancyAndOccupiation;
  previousYearCount: LocationVacancyAndOccupiation;
  totalCounts: LocationVacancyAndOccupiation;
};

export type LocationDto = {
  __typename?: 'LocationDTO';
  addVatAutomatically?: Maybe<Scalars['Boolean']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  billDotComDefaultBank?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CountryDto>;
  countryId?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<CurrencyDto>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  daypassPrice?: Maybe<Scalars['Int']['output']>;
  disableAutoCharge?: Maybe<Scalars['Int']['output']>;
  ein?: Maybe<Scalars['String']['output']>;
  estDate?: Maybe<Scalars['String']['output']>;
  floorPlans?: Maybe<Scalars['String']['output']>;
  has12Hours?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  kisiGroup?: Maybe<Scalars['String']['output']>;
  legalEntity?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  occupiedTotal: Scalars['Float']['output'];
  paymentKey?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  quickBookClassId?: Maybe<Scalars['String']['output']>;
  quickBookClassName?: Maybe<Scalars['String']['output']>;
  settingPrice?: Maybe<Scalars['Float']['output']>;
  supportEmails?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  vat?: Maybe<Scalars['Int']['output']>;
  zone?: Maybe<ZoneDto>;
  zoneId?: Maybe<Scalars['Int']['output']>;
};

export type LocationEntity = {
  __typename?: 'LocationEntity';
  addVatAutomatically?: Maybe<Scalars['Boolean']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  billDotComDefaultBank?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  countryId?: Maybe<Scalars['Int']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  daypassPrice?: Maybe<Scalars['Int']['output']>;
  disableAutoCharge?: Maybe<Scalars['Int']['output']>;
  ein?: Maybe<Scalars['String']['output']>;
  estDate?: Maybe<Scalars['String']['output']>;
  floorPlans?: Maybe<Scalars['String']['output']>;
  has12Hours?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  kisiGroup?: Maybe<Scalars['String']['output']>;
  legalEntity?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paymentKey?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  quickBookClassId?: Maybe<Scalars['String']['output']>;
  quickBookClassName?: Maybe<Scalars['String']['output']>;
  supportEmails?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  vat?: Maybe<Scalars['Int']['output']>;
  zoneId?: Maybe<Scalars['Int']['output']>;
};

export type LocationHeaderDto = {
  __typename?: 'LocationHeaderDTO';
  next_month: Scalars['Float']['output'];
  prev_year: Scalars['Float']['output'];
  this_month: Scalars['Float']['output'];
};

export type LocationNameByUserDto = {
  __typename?: 'LocationNameByUserDTO';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type LocationPaginator = {
  __typename?: 'LocationPaginator';
  data: Array<LocationDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type LocationPercentageStatisticsDto = {
  __typename?: 'LocationPercentageStatisticsDTO';
  nextMonthPercent: Scalars['Float']['output'];
  occupancyRatio: Scalars['Float']['output'];
  outstanding: Scalars['Float']['output'];
  previousMonthPercent: Scalars['Float']['output'];
};

export type LocationSettingDayPriceDto = {
  __typename?: 'LocationSettingDayPriceDTO';
  locationPrice: Scalars['Int']['output'];
  settingPrice: Scalars['Int']['output'];
};

export type LocationStats = {
  __typename?: 'LocationStats';
  Coworking: Scalars['Float']['output'];
  Virtual: Scalars['Float']['output'];
  desks: Scalars['Float']['output'];
  office: Scalars['Float']['output'];
};

export type LocationVacancyAndOccupiation = {
  __typename?: 'LocationVacancyAndOccupiation';
  total: LocationStats;
  vacant: LocationStats;
};

export type LockDto = {
  __typename?: 'LockDto';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type LoginUser = {
  cipheredCredentials?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

/** Status of the media */
export enum MediaStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Success = 'SUCCESS',
}

export type MemberDto = {
  __typename?: 'MemberDTO';
  additionalEmails?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  autoChargeDay?: Maybe<Scalars['Float']['output']>;
  billingEmail?: Maybe<Scalars['String']['output']>;
  ccMask?: Maybe<Scalars['String']['output']>;
  checksAllowed?: Maybe<Scalars['Float']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  customCurrency?: Maybe<Scalars['String']['output']>;
  customCurrencyAuto?: Maybe<Scalars['String']['output']>;
  customCurrencyOn?: Maybe<Scalars['String']['output']>;
  customCurrencyRate?: Maybe<Scalars['String']['output']>;
  customLateFeeCash?: Maybe<Scalars['Float']['output']>;
  customLateFeeDays?: Maybe<Scalars['Float']['output']>;
  customLateFeeOn?: Maybe<Scalars['String']['output']>;
  customLateFeePercentage?: Maybe<Scalars['Float']['output']>;
  disableAutoCharge?: Maybe<Scalars['Float']['output']>;
  disableAutoInvoice?: Maybe<Scalars['Float']['output']>;
  dob?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  entryInfo?: Maybe<Scalars['String']['output']>;
  faxNumber?: Maybe<Scalars['String']['output']>;
  greenInvoiceAccountingCode?: Maybe<Scalars['String']['output']>;
  greenInvoiceDocumentType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  industryId?: Maybe<Scalars['Float']['output']>;
  insuranceExp?: Maybe<Scalars['String']['output']>;
  isContractNotReady?: Maybe<Scalars['Boolean']['output']>;
  isExternal?: Maybe<Scalars['Float']['output']>;
  isSentToQuickBook?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextInvoiceDate?: Maybe<Scalars['String']['output']>;
  payAuthToken?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  referralId?: Maybe<Scalars['Float']['output']>;
  revShareAgreement?: Maybe<Scalars['String']['output']>;
  revShareOn?: Maybe<Scalars['String']['output']>;
  sapCardValidUntil?: Maybe<Scalars['String']['output']>;
  sapCreditCardNumber?: Maybe<Scalars['String']['output']>;
  sapDeductionEnabled?: Maybe<Scalars['Float']['output']>;
  sapDocumentType?: Maybe<Scalars['String']['output']>;
  sapFederalTaxId?: Maybe<Scalars['String']['output']>;
  sapMemberCode?: Maybe<Scalars['String']['output']>;
  smartContractId?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tempPassword?: Maybe<Scalars['String']['output']>;
  tenantClass?: Maybe<Scalars['String']['output']>;
};

export type MemberEntity = {
  __typename?: 'MemberEntity';
  additionalEmails?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  autoChargeDay?: Maybe<Scalars['Float']['output']>;
  billingEmail?: Maybe<Scalars['String']['output']>;
  ccMask?: Maybe<Scalars['String']['output']>;
  checksAllowed?: Maybe<Scalars['Float']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  customCurrency?: Maybe<Scalars['String']['output']>;
  customCurrencyAuto?: Maybe<Scalars['String']['output']>;
  customCurrencyOn?: Maybe<Scalars['String']['output']>;
  customCurrencyRate?: Maybe<Scalars['String']['output']>;
  customLateFeeCash?: Maybe<Scalars['Float']['output']>;
  customLateFeeDays?: Maybe<Scalars['Float']['output']>;
  customLateFeeOn?: Maybe<Scalars['String']['output']>;
  customLateFeePercentage?: Maybe<Scalars['Float']['output']>;
  disableAutoCharge?: Maybe<Scalars['Float']['output']>;
  disableAutoInvoice?: Maybe<Scalars['Float']['output']>;
  dob?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  entryInfo?: Maybe<Scalars['String']['output']>;
  faxNumber?: Maybe<Scalars['String']['output']>;
  greenInvoiceAccountingCode?: Maybe<Scalars['String']['output']>;
  greenInvoiceDocumentType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  industryId?: Maybe<Scalars['Float']['output']>;
  insuranceExp?: Maybe<Scalars['String']['output']>;
  isContractNotReady?: Maybe<Scalars['Boolean']['output']>;
  isExternal?: Maybe<Scalars['Float']['output']>;
  isSentToQuickBook?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextInvoiceDate?: Maybe<Scalars['String']['output']>;
  payAuthToken?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  referralId?: Maybe<Scalars['Float']['output']>;
  revShareAgreement?: Maybe<Scalars['String']['output']>;
  revShareOn?: Maybe<Scalars['String']['output']>;
  sapCardValidUntil?: Maybe<Scalars['String']['output']>;
  sapCreditCardNumber?: Maybe<Scalars['String']['output']>;
  sapDeductionEnabled?: Maybe<Scalars['Float']['output']>;
  sapDocumentType?: Maybe<Scalars['String']['output']>;
  sapFederalTaxId?: Maybe<Scalars['String']['output']>;
  sapMemberCode?: Maybe<Scalars['String']['output']>;
  smartContractId?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tempPassword?: Maybe<Scalars['String']['output']>;
  tenantClass?: Maybe<Scalars['String']['output']>;
};

export type MemberPaginator = {
  __typename?: 'MemberPaginator';
  data: Array<MemberDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type Mutation = {
  __typename?: 'Mutation';
  changePassword: UserDtoWithoutToken;
  createAccountingAccount: AccountingAccountDto;
  createAccountingCode: ReconciliationDto;
  createApplication?: Maybe<ApplicationDto>;
  createCheck: CheckDto;
  createCompany?: Maybe<CompanyDto>;
  createContent: ContentDto;
  createContract?: Maybe<ContractDto>;
  createContractTemplate: ContractTemplateDto;
  createCustomFieldValues: Array<CustomFieldValueDto>;
  createEmailGroup: EmailGroupDto;
  createEmailGroupCondition: Array<EmailGroupConditionDto>;
  createEmailSender: EmailSenderDto;
  createEmailTemplate: EmailTemplateDto;
  createExpense: ExpenseDto;
  createExpenseType: ExpenseTypeDto;
  createInspection: InspectionDto;
  createInvoice: InvoiceDto;
  createJournalEntry: JournalEntryDto;
  createLocation: LocationDto;
  createLocationTransactionAccount: Scalars['Boolean']['output'];
  createMember: MemberDto;
  createOffice: OfficeDto;
  createOfficeNote: OfficeNoteDto;
  createOutgoingPayment: OutgoingPaymentDto;
  createPackage: PackageDto;
  createPayment: Array<PaymentDto>;
  createPaymentBatch: PaymentBatchDto;
  createPushToken: PushTokenDto;
  createScheduledIncrease: ScheduledIncreaseDto;
  createTour?: Maybe<TourDto>;
  createTransactionAccount: TransactionAccountDto;
  createTransactionAccountQuestion: TransactionAccountQuestionDto;
  createVendor: VendorDto;
  declineContract?: Maybe<ContractDto>;
  logMemberAutoInvoice: Scalars['String']['output'];
  login: UserDto;
  notifyMembersByMailWithMessage: Scalars['String']['output'];
  removeCheck: Scalars['Int']['output'];
  removeContent: Scalars['Int']['output'];
  removeContract?: Maybe<ContractDto>;
  removeContractTemplate: ContractTemplateDto;
  removeCurrentMemberFromOffice: OfficeDto;
  removeEmailGroup: Scalars['Int']['output'];
  removeEmailGroupCondition: Scalars['Int']['output'];
  removeEmailSender: Scalars['Int']['output'];
  removeEmailTemplate: Scalars['Int']['output'];
  removeExpense: Scalars['Int']['output'];
  removeExpenseType: Scalars['Int']['output'];
  removeFutureMemberFromOffice?: Maybe<OfficeDto>;
  removeInspection: Scalars['Int']['output'];
  removeInvoice: InvoiceDto;
  removeJournalEntry: Scalars['Int']['output'];
  removeLeaveMemberDateFromOffice: OfficeDto;
  removeMember: MemberDto;
  removeOffice: Scalars['Int']['output'];
  removeOfficeNote: Scalars['Int']['output'];
  removeOutgoingPayment: Scalars['Int']['output'];
  removePackage: Scalars['Int']['output'];
  removePayment: Scalars['Int']['output'];
  removeScheduledIncrease: Scalars['Int']['output'];
  removeTransactionAccount: Scalars['Int']['output'];
  removeTransactionAccountQuestion: Scalars['Int']['output'];
  resetPassword: UserDtoWithoutToken;
  sendPackageReminder: Scalars['String']['output'];
  unlock: UnlockResponseDto;
  updateAccountingAccount: AccountingAccountDto;
  updateAccountingCode: ReconciliationDto;
  updateCheck: CheckDto;
  updateContent: ContentDto;
  updateEmailGroup: EmailGroupDto;
  updateEmailGroupCondition: Array<EmailGroupConditionDto>;
  updateEmailSender: EmailSenderDto;
  updateEmailTemplate: EmailTemplateDto;
  updateExpense: ExpenseDto;
  updateExpenseType: ExpenseTypeDto;
  updateInspection: InspectionDto;
  updateInvoice: InvoiceDto;
  updateJournalEntry: JournalEntryDto;
  updateLocation: LocationDto;
  updateMember: MemberDto;
  updateOffice?: Maybe<OfficeDto>;
  updateOutgoingPayment: OutgoingPaymentDto;
  updatePackage: PackageDto;
  updatePayment: PaymentDto;
  updatePaymentBatch: PaymentBatchDto;
  updateSetting: SettingDto;
  updateTransactionAccount: TransactionAccountDto;
  updateTransactionAccountQuestion: TransactionAccountQuestionDto;
  updateUser?: Maybe<UserDtoWithoutToken>;
  updateUserInfo: UserInfoDto;
  updateVendor: VendorDto;
};

export type MutationChangePasswordArgs = {
  data: ChangePassword;
};

export type MutationCreateAccountingAccountArgs = {
  data: CreateAccountingAccount;
};

export type MutationCreateAccountingCodeArgs = {
  data: CreateReconciliation;
};

export type MutationCreateApplicationArgs = {
  data: PublicCreateApplicationValidation;
};

export type MutationCreateCheckArgs = {
  data: CreateCheck;
};

export type MutationCreateCompanyArgs = {
  data: CreateCompanyValidation;
};

export type MutationCreateContentArgs = {
  data: CreateContent;
};

export type MutationCreateContractArgs = {
  data: CreateSmartContract;
};

export type MutationCreateContractTemplateArgs = {
  data: CreateContractTemplate;
};

export type MutationCreateCustomFieldValuesArgs = {
  data: Array<CreateCustomFieldValueValidation>;
};

export type MutationCreateEmailGroupArgs = {
  data: CreateEmailGroup;
};

export type MutationCreateEmailGroupConditionArgs = {
  data: Array<CreateEmailGroupCondition>;
};

export type MutationCreateEmailSenderArgs = {
  data: CreateEmailSender;
};

export type MutationCreateEmailTemplateArgs = {
  data: CreateEmailTemplate;
};

export type MutationCreateExpenseArgs = {
  data: CreateExpense;
};

export type MutationCreateExpenseTypeArgs = {
  data: CreateExpenseType;
};

export type MutationCreateInspectionArgs = {
  data: CreateInspection;
};

export type MutationCreateInvoiceArgs = {
  data: CreateInvoice;
};

export type MutationCreateJournalEntryArgs = {
  data: CreateJournalEntry;
};

export type MutationCreateLocationArgs = {
  data: CreateLocation;
};

export type MutationCreateLocationTransactionAccountArgs = {
  data: Array<CreateLocationTransactionAccount>;
};

export type MutationCreateMemberArgs = {
  data: CreateMember;
};

export type MutationCreateOfficeArgs = {
  data: CreateOffice;
};

export type MutationCreateOfficeNoteArgs = {
  data: CreateOfficeNote;
};

export type MutationCreateOutgoingPaymentArgs = {
  data: CreateOutgoingPayment;
};

export type MutationCreatePackageArgs = {
  data: CreatePackage;
};

export type MutationCreatePaymentArgs = {
  data: Array<CreatePayment>;
};

export type MutationCreatePaymentBatchArgs = {
  data: CreatePaymentBatch;
};

export type MutationCreatePushTokenArgs = {
  data: CreatePushToken;
};

export type MutationCreateScheduledIncreaseArgs = {
  data: CreateScheduledIncrease;
};

export type MutationCreateTourArgs = {
  data: CreateTour;
};

export type MutationCreateTransactionAccountArgs = {
  data: CreateTransactionAccount;
};

export type MutationCreateTransactionAccountQuestionArgs = {
  data: CreateTransactionAccountQuestion;
};

export type MutationCreateVendorArgs = {
  data: CreateVendor;
};

export type MutationDeclineContractArgs = {
  data: DeclineSmartContract;
};

export type MutationLogMemberAutoInvoiceArgs = {
  data: CreateMemberLogInvoice;
};

export type MutationLoginArgs = {
  data: LoginUser;
};

export type MutationNotifyMembersByMailWithMessageArgs = {
  data: SendMemberAnEmailMessage;
};

export type MutationRemoveCheckArgs = {
  data: RemoveCheck;
};

export type MutationRemoveContentArgs = {
  data: RemoveContent;
};

export type MutationRemoveContractArgs = {
  data: RemoveSmartContract;
};

export type MutationRemoveContractTemplateArgs = {
  data: RemoveContractTemplate;
};

export type MutationRemoveCurrentMemberFromOfficeArgs = {
  data: RemoveOffice;
};

export type MutationRemoveEmailGroupArgs = {
  data: RemoveEmailGroup;
};

export type MutationRemoveEmailGroupConditionArgs = {
  data: RemoveEmailGroupCondition;
};

export type MutationRemoveEmailSenderArgs = {
  data: RemoveEmailSender;
};

export type MutationRemoveEmailTemplateArgs = {
  data: RemoveEmailTemplate;
};

export type MutationRemoveExpenseArgs = {
  data: RemoveExpense;
};

export type MutationRemoveExpenseTypeArgs = {
  data: RemoveExpenseType;
};

export type MutationRemoveFutureMemberFromOfficeArgs = {
  data: RemoveOffice;
};

export type MutationRemoveInspectionArgs = {
  data: RemoveInspection;
};

export type MutationRemoveInvoiceArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveJournalEntryArgs = {
  data: RemoveJournalEntry;
};

export type MutationRemoveLeaveMemberDateFromOfficeArgs = {
  data: RemoveOffice;
};

export type MutationRemoveMemberArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveOfficeArgs = {
  data: RemoveOffice;
};

export type MutationRemoveOfficeNoteArgs = {
  data: RemoveOfficeNote;
};

export type MutationRemoveOutgoingPaymentArgs = {
  data: RemoveOutgoingPayment;
};

export type MutationRemovePackageArgs = {
  data: RemovePackage;
};

export type MutationRemovePaymentArgs = {
  data: RemovePayment;
};

export type MutationRemoveScheduledIncreaseArgs = {
  data: RemoveScheduledIncreaseById;
};

export type MutationRemoveTransactionAccountArgs = {
  data: RemoveTransactionAccount;
};

export type MutationRemoveTransactionAccountQuestionArgs = {
  data: RemoveTransactionAccountQuestion;
};

export type MutationResetPasswordArgs = {
  data: ResetUserPassword;
};

export type MutationSendPackageReminderArgs = {
  data: RemovePackage;
};

export type MutationUnlockArgs = {
  data: UnlockDoor;
};

export type MutationUpdateAccountingAccountArgs = {
  data: UpdateAccountingAccount;
};

export type MutationUpdateAccountingCodeArgs = {
  data: UpdateReconciliation;
};

export type MutationUpdateCheckArgs = {
  data: UpdateCheck;
};

export type MutationUpdateContentArgs = {
  data: UpdateContent;
};

export type MutationUpdateEmailGroupArgs = {
  data: UpdateEmailGroup;
};

export type MutationUpdateEmailGroupConditionArgs = {
  data: Array<UpdateEmailGroupCondition>;
};

export type MutationUpdateEmailSenderArgs = {
  data: UpdateEmailSender;
};

export type MutationUpdateEmailTemplateArgs = {
  data: UpdateEmailTemplate;
  param: RemoveEmailTemplate;
};

export type MutationUpdateExpenseArgs = {
  data: UpdateExpense;
};

export type MutationUpdateExpenseTypeArgs = {
  data: UpdateExpenseType;
};

export type MutationUpdateInspectionArgs = {
  data: UpdateInspection;
};

export type MutationUpdateInvoiceArgs = {
  data: UpdateInvoice;
};

export type MutationUpdateJournalEntryArgs = {
  data: UpdateJournalEntry;
};

export type MutationUpdateLocationArgs = {
  data: UpdateLocation;
};

export type MutationUpdateMemberArgs = {
  updateMemberInput: UpdateMember;
};

export type MutationUpdateOfficeArgs = {
  data: UpdateOffice;
};

export type MutationUpdateOutgoingPaymentArgs = {
  data: UpdateOutgoingPayment;
};

export type MutationUpdatePackageArgs = {
  data: UpdatePackage;
};

export type MutationUpdatePaymentArgs = {
  data: UpdatePayment;
};

export type MutationUpdatePaymentBatchArgs = {
  data: UpdatePaymentBatch;
};

export type MutationUpdateSettingArgs = {
  data: UpdateClientSettings;
  id: Scalars['Float']['input'];
};

export type MutationUpdateTransactionAccountArgs = {
  data: UpdateTransactionAccount;
};

export type MutationUpdateTransactionAccountQuestionArgs = {
  data: UpdateTransactionAccountQuestion;
};

export type MutationUpdateUserArgs = {
  data: UpdateUser;
};

export type MutationUpdateUserInfoArgs = {
  data: UpdateUserInfo;
};

export type MutationUpdateVendorArgs = {
  data: UpdateVendor;
};

export type NonMemberPaymentReport = {
  __typename?: 'NonMemberPaymentReport';
  header: Scalars['String']['output'];
  records: Scalars['String']['output'];
};

export type OfficeDto = {
  __typename?: 'OfficeDTO';
  accountingCode?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  allowDaypass?: Maybe<Scalars['Boolean']['output']>;
  allowZillow: Scalars['Boolean']['output'];
  amenities: Array<AmenityDto>;
  approxValue?: Maybe<Scalars['String']['output']>;
  availableDate?: Maybe<Scalars['String']['output']>;
  baseAttributes?: Maybe<Array<BasicAttributesDto>>;
  baseRate?: Maybe<Scalars['String']['output']>;
  bathrooms?: Maybe<Scalars['String']['output']>;
  bedrooms?: Maybe<Scalars['String']['output']>;
  brokerPhoneNumber?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<GeoJson>;
  country?: Maybe<Scalars['String']['output']>;
  createdAfterHistDate?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['DateTime']['output'];
  curMemberEndDate?: Maybe<Scalars['DateTime']['output']>;
  curMemberEndTime?: Maybe<Scalars['String']['output']>;
  curMemberLeaseEndDate?: Maybe<Scalars['DateTime']['output']>;
  curMemberLeaseStartDate?: Maybe<Scalars['DateTime']['output']>;
  curMemberStartDate?: Maybe<Scalars['DateTime']['output']>;
  curMemberid?: Maybe<Scalars['Int']['output']>;
  curRate?: Maybe<Scalars['String']['output']>;
  daypassPrice?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discountRate?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  enableMarketing?: Maybe<Scalars['Boolean']['output']>;
  entryInfo?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  formTemplateId?: Maybe<Scalars['Int']['output']>;
  futureMemberDiscountRate?: Maybe<Scalars['String']['output']>;
  futureMemberEndDate?: Maybe<Scalars['DateTime']['output']>;
  futureMemberLeaseEndDate?: Maybe<Scalars['DateTime']['output']>;
  futureMemberLeaseStartDate?: Maybe<Scalars['DateTime']['output']>;
  futureMemberNumOfTerms?: Maybe<Scalars['String']['output']>;
  futureMemberRate?: Maybe<Scalars['String']['output']>;
  futureMemberStartDate?: Maybe<Scalars['DateTime']['output']>;
  futureMemberid?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  kisiGroup?: Maybe<Scalars['String']['output']>;
  leaveReason?: Maybe<Scalars['String']['output']>;
  listingMedia?: Maybe<Array<ListingMedia>>;
  location: LocationDto;
  locationid?: Maybe<Scalars['Int']['output']>;
  marketingText?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  numOfBWPrints?: Maybe<Scalars['String']['output']>;
  numOfColorPrints?: Maybe<Scalars['String']['output']>;
  numOfConfRoomHours?: Maybe<Scalars['String']['output']>;
  numOfTerms?: Maybe<Scalars['String']['output']>;
  openPathGroup?: Maybe<Scalars['Int']['output']>;
  parkingNumber?: Maybe<Scalars['String']['output']>;
  petsAllowed?: Maybe<Scalars['Boolean']['output']>;
  photos?: Maybe<Scalars['String']['output']>;
  postBoxNumber?: Maybe<Scalars['String']['output']>;
  primaryPhoto?: Maybe<Scalars['String']['output']>;
  propertyType?: Maybe<PropertyType>;
  seats?: Maybe<Scalars['String']['output']>;
  sqft?: Maybe<Scalars['Float']['output']>;
  stage?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  visibleToBrokers?: Maybe<Scalars['Int']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type OfficeFilterEntity = {
  __typename?: 'OfficeFilterEntity';
  componentType: Scalars['String']['output'];
  fieldName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isMultiSelect: Scalars['String']['output'];
  label: Scalars['String']['output'];
  maxLength?: Maybe<Scalars['Float']['output']>;
  maxValue?: Maybe<Scalars['Float']['output']>;
  minValue?: Maybe<Scalars['Float']['output']>;
  options?: Maybe<Array<Option>>;
  placeholder?: Maybe<Scalars['String']['output']>;
  preview?: Maybe<Scalars['String']['output']>;
  secondaryOperator?: Maybe<Operator>;
};

export type OfficeHistoryDto = {
  __typename?: 'OfficeHistoryDTO';
  id: Scalars['ID']['output'];
  leaveReason?: Maybe<Scalars['String']['output']>;
  memberEndDate?: Maybe<Scalars['DateTime']['output']>;
  memberStartDate: Scalars['DateTime']['output'];
  memberid?: Maybe<Scalars['Int']['output']>;
  noticeGivenDate?: Maybe<Scalars['DateTime']['output']>;
  officeid: Scalars['Int']['output'];
  oldOfficeId: Scalars['Int']['output'];
  ratePaid?: Maybe<Scalars['String']['output']>;
  salesDate?: Maybe<Scalars['DateTime']['output']>;
  salespersonId?: Maybe<Scalars['Int']['output']>;
};

export type OfficeNoteDto = {
  __typename?: 'OfficeNoteDTO';
  date?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  status?: Maybe<Scalars['String']['output']>;
};

export type OfficePaginator = {
  __typename?: 'OfficePaginator';
  data: Array<OfficeDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type OfficeTypeDto = {
  __typename?: 'OfficeTypeDto';
  amenities: Array<AmenityDto>;
  filters: Array<OfficeFilterEntity>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  propertyTypes?: Maybe<Array<Scalars['String']['output']>>;
};

export enum Operator {
  Between = 'between',
  Contains = 'contains',
  EndsWith = 'ends_with',
  Equals = 'equals',
  GreaterThan = 'greater_than',
  GreaterThanOrEqual = 'greater_than_or_equal',
  In = 'in',
  LessThan = 'less_than',
  LessThanOrEqual = 'less_than_or_equal',
  Like = 'like',
  Not = 'not',
  StartsWith = 'starts_with',
}

export type Option = {
  __typename?: 'Option';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type OutgoingPaymentDto = {
  __typename?: 'OutgoingPaymentDTO';
  amount: Scalars['Int']['output'];
  billBankAccountId?: Maybe<Scalars['String']['output']>;
  billErrorMessage?: Maybe<Scalars['String']['output']>;
  billPaymentId?: Maybe<Scalars['String']['output']>;
  checkId?: Maybe<Scalars['Int']['output']>;
  expenseAccountId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  invoiceId: Scalars['Int']['output'];
  isBillAdded?: Maybe<Scalars['Int']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  processDate: Scalars['String']['output'];
  reconciliationId?: Maybe<Scalars['Int']['output']>;
  selected?: Maybe<Scalars['Boolean']['output']>;
  type: Scalars['String']['output'];
  vendorId: Scalars['Int']['output'];
};

export type OutgoingPaymentPaginator = {
  __typename?: 'OutgoingPaymentPaginator';
  data: Array<OutgoingPaymentDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type PackageDto = {
  __typename?: 'PackageDTO';
  createdAt: Scalars['DateTime']['output'];
  delieveredAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  locationId?: Maybe<Scalars['Float']['output']>;
  member?: Maybe<MemberDto>;
  memberId?: Maybe<Scalars['Float']['output']>;
  memberName?: Maybe<Scalars['String']['output']>;
  pickedAt: Scalars['DateTime']['output'];
  pickedBy: Scalars['String']['output'];
  status: PackageStatuses;
  type: Types;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<MemberDto>;
  userId?: Maybe<Scalars['Float']['output']>;
};

export type PackagePaginator = {
  __typename?: 'PackagePaginator';
  data: Array<PackageDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export enum PackageStatuses {
  NotPicked = 'NOT_PICKED',
  Picked = 'PICKED',
}

export type Paginate = {
  page: Scalars['Int']['input'];
  per_page: Scalars['Int']['input'];
};

export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  count: Scalars['Int']['output'];
  currentPage: Scalars['Int']['output'];
  firstItem: Scalars['Int']['output'];
  hasMorePages: Scalars['Boolean']['output'];
  lastItem: Scalars['Float']['output'];
  lastPage: Scalars['Float']['output'];
  perPage: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type PaymentBankAccountDetailsDto = {
  __typename?: 'PaymentBankAccountDetailsDTO';
  Vendor: PaymentVendorDetailsDto;
  address: Scalars['String']['output'];
  bank_address: Scalars['String']['output'];
  bank_name: Scalars['String']['output'];
  company_id: Scalars['Int']['output'];
  created_at: Scalars['String']['output'];
  edrpou: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  legal_name: Scalars['String']['output'];
  merchant_id: Scalars['Int']['output'];
  merchant_site_id: Scalars['Int']['output'];
  mfo: Scalars['String']['output'];
  name: Scalars['String']['output'];
  office_boy_api_key: Scalars['String']['output'];
  office_boy_company_id: Scalars['Int']['output'];
  office_boy_public_api_key: Scalars['String']['output'];
  payment_key: Scalars['String']['output'];
  phone_number: Scalars['String']['output'];
  seller_id: Scalars['Int']['output'];
  seller_payme_id: Scalars['Int']['output'];
  seller_payme_secret: Scalars['String']['output'];
  swift: Scalars['String']['output'];
  updated_at: Scalars['String']['output'];
  vendor_id: Scalars['Int']['output'];
};

export type PaymentBatchDto = {
  __typename?: 'PaymentBatchDTO';
  amount?: Maybe<Scalars['Float']['output']>;
  authorizer: Scalars['Int']['output'];
  correlationId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  date: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  fromTransactionAccountId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isPaymentProcessorBatch: Scalars['Boolean']['output'];
  paymentProcessorType?: Maybe<Scalars['String']['output']>;
  reconciliationId?: Maybe<Scalars['Int']['output']>;
  selected?: Maybe<Scalars['Boolean']['output']>;
  toTransactionAccountId?: Maybe<Scalars['Int']['output']>;
};

export type PaymentBatchPaginator = {
  __typename?: 'PaymentBatchPaginator';
  data: Array<PaymentBatchDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type PaymentDto = {
  __typename?: 'PaymentDTO';
  adminId?: Maybe<Scalars['Int']['output']>;
  amount?: Maybe<Scalars['Int']['output']>;
  amountLeft?: Maybe<Scalars['Float']['output']>;
  authorize?: Maybe<Scalars['String']['output']>;
  checkId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creditCard?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deduction: Scalars['Float']['output'];
  depositDate?: Maybe<Scalars['DateTime']['output']>;
  depositId?: Maybe<Scalars['Int']['output']>;
  deposits: Array<PaymentEntity>;
  description?: Maybe<Scalars['String']['output']>;
  greenInvoicePaymentId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  incomeAccountId?: Maybe<Scalars['Int']['output']>;
  invoice?: Maybe<InvoiceDto>;
  invoiceId?: Maybe<Scalars['Int']['output']>;
  isDeposit?: Maybe<Scalars['Int']['output']>;
  member?: Maybe<MemberEntity>;
  memberId?: Maybe<Scalars['Int']['output']>;
  payable?: Maybe<Scalars['Boolean']['output']>;
  paymentBatchId?: Maybe<Scalars['Int']['output']>;
  paymentCorrelationId?: Maybe<Scalars['String']['output']>;
  paymentIntegrationType?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  paymentType?: Maybe<Scalars['String']['output']>;
  quickBookPaymentId?: Maybe<Scalars['String']['output']>;
  reconciliationId?: Maybe<Scalars['Int']['output']>;
  referralId?: Maybe<Scalars['Float']['output']>;
  sapAccountNum?: Maybe<Scalars['String']['output']>;
  sapBankCode?: Maybe<Scalars['String']['output']>;
  sapBranch?: Maybe<Scalars['String']['output']>;
  sapPaymentId?: Maybe<Scalars['String']['output']>;
  selected?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transaction?: Maybe<TransactionAccountEntity>;
  vendor?: Maybe<VendorEntity>;
  xeroPaymentId?: Maybe<Scalars['String']['output']>;
};

export type PaymentEntity = {
  __typename?: 'PaymentEntity';
  adminId?: Maybe<Scalars['Int']['output']>;
  amount?: Maybe<Scalars['Int']['output']>;
  amountLeft?: Maybe<Scalars['Float']['output']>;
  authorize?: Maybe<Scalars['String']['output']>;
  checkId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creditCard?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deduction: Scalars['Float']['output'];
  depositDate?: Maybe<Scalars['DateTime']['output']>;
  depositId?: Maybe<Scalars['Int']['output']>;
  deposits: Array<PaymentEntity>;
  description?: Maybe<Scalars['String']['output']>;
  greenInvoicePaymentId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  incomeAccountId?: Maybe<Scalars['Int']['output']>;
  invoiceId?: Maybe<Scalars['Int']['output']>;
  isDeposit?: Maybe<Scalars['Int']['output']>;
  member?: Maybe<MemberEntity>;
  memberId?: Maybe<Scalars['Int']['output']>;
  payable?: Maybe<Scalars['Boolean']['output']>;
  paymentBatchId?: Maybe<Scalars['Int']['output']>;
  paymentCorrelationId?: Maybe<Scalars['String']['output']>;
  paymentIntegrationType?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  paymentType?: Maybe<Scalars['String']['output']>;
  quickBookPaymentId?: Maybe<Scalars['String']['output']>;
  reconciliationId?: Maybe<Scalars['Int']['output']>;
  referralId?: Maybe<Scalars['Float']['output']>;
  sapAccountNum?: Maybe<Scalars['String']['output']>;
  sapBankCode?: Maybe<Scalars['String']['output']>;
  sapBranch?: Maybe<Scalars['String']['output']>;
  sapPaymentId?: Maybe<Scalars['String']['output']>;
  selected?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transaction?: Maybe<TransactionAccountEntity>;
  vendor?: Maybe<VendorEntity>;
  xeroPaymentId?: Maybe<Scalars['String']['output']>;
};

export type PaymentLocationDetailsDto = {
  __typename?: 'PaymentLocationDetailsDTO';
  BankAccount: PaymentBankAccountDetailsDto;
  bank_account_id: Scalars['Int']['output'];
  company_token: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  location_id: Scalars['Int']['output'];
  max_amount: Scalars['Int']['output'];
  updated_at: Scalars['String']['output'];
};

export type PaymentLogDto = {
  __typename?: 'PaymentLogDTO';
  action: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  ip?: Maybe<Scalars['String']['output']>;
  memberId: Scalars['Int']['output'];
};

export type PaymentLogPaginator = {
  __typename?: 'PaymentLogPaginator';
  data: Array<PaymentLogDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type PaymentPaginator = {
  __typename?: 'PaymentPaginator';
  data: Array<PaymentDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type PaymentServiceDto = {
  __typename?: 'PaymentServiceDTO';
  code: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['String']['output'];
};

export type PaymentTypeDto = {
  __typename?: 'PaymentTypeDTO';
  VendorPaymentTypes?: Maybe<Array<VendorPaymentTypeDto>>;
  created_at: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['String']['output'];
};

export type PaymentVendorDetailsDto = {
  __typename?: 'PaymentVendorDetailsDTO';
  PaymentTypes: Array<PaymentTypeDto>;
  code: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['String']['output'];
};

export type PermissionEntity = {
  __typename?: 'PermissionEntity';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PipelineDto = {
  __typename?: 'PipelineDTO';
  id: Scalars['ID']['output'];
};

/** Property Type */
export enum PropertyType {
  Apartment = 'APARTMENT',
  Condo = 'CONDO',
  Coop = 'COOP',
  Manufactured = 'MANUFACTURED',
  MultiFamily = 'MULTI_FAMILY',
  Other = 'OTHER',
  SingleFamily = 'SINGLE_FAMILY',
  TownHouse = 'TOWN_HOUSE',
  VacantLand = 'VACANT_LAND',
}

export type PublicCreateApplicationValidation = {
  application: CreateApplicationValidation;
  formValues: Array<PublicCreateCustomFieldValueValidation>;
  lead: CreateLeadValidation;
};

export type PublicCreateCustomFieldValueValidation = {
  customFieldId: Scalars['Int']['input'];
  formTemplateId: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

export type PublicFormTemplateDto = {
  __typename?: 'PublicFormTemplateDTO';
  fields?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type PushTokenDto = {
  __typename?: 'PushTokenDTO';
  createdAt: Scalars['DateTime']['output'];
  deviceOs: DeviceOs;
  id: Scalars['ID']['output'];
  token: Scalars['String']['output'];
  user?: Maybe<UserDto>;
};

export type PushTokenPaginator = {
  __typename?: 'PushTokenPaginator';
  data: Array<PushTokenDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type Query = {
  __typename?: 'Query';
  Inspection?: Maybe<InspectionDto>;
  Inspections: InspectionPaginator;
  accountAccount: AccountingAccountDto;
  accountAccounts: AccountingAccountPaginator;
  accountCode: ReconciliationDto;
  accountCodes: AccountingCodePaginator;
  check?: Maybe<CheckDto>;
  checks: CheckPaginator;
  content: ContentDto;
  contents: ContentPaginator;
  contract: ContractDto;
  contractTemplate: ContractTemplateDto;
  contractTemplates: ContractTemplatePaginator;
  contracts: ContractPaginator;
  countries: CountryPaginator;
  country: CountryDto;
  currencies: CurrencyPaginator;
  currency: CurrencyDto;
  currencyByCode: CurrencyDto;
  emailGroup: EmailGroupDto;
  emailGroupCondition: EmailGroupConditionDto;
  emailGroupConditions: EmailGroupConditionPaginator;
  emailGroups: EmailGroupPaginator;
  emailSender: EmailSenderDto;
  emailSenders: EmailSenderPaginator;
  emailTemplate: EmailTemplateDto;
  emailTemplates: EmailTemplatePagintor;
  emailVariables: EmailVariablePaginator;
  expense: ExpenseDto;
  expenseType: ExpenseTypeDto;
  expenseTypes: ExpenseTypePaginator;
  expenses: ExpensePaginator;
  findAllAvailableOfficesByLocations: Array<OfficeDto>;
  findAllLeaseEndingOfficesByLocations: Array<OfficeDto>;
  findAllLocationHeaders: LocationHeaderDto;
  findAllLocationMembers: LocationPaginator;
  findAllLocationVendors: Array<PaymentServiceDto>;
  findDaypassPriceLocationAndSetting?: Maybe<LocationSettingDayPriceDto>;
  findLocationCountStatistics?: Maybe<LocationCountStatisticsDto>;
  findLocationNameOfUser: Array<LocationNameByUserDto>;
  findLocationPaymentDetails?: Maybe<PaymentLocationDetailsDto>;
  findLocationPercentageStatistics?: Maybe<LocationPercentageStatisticsDto>;
  findUserWithLocationHeader?: Maybe<Array<UserDtoWithoutToken>>;
  generateReportForNonTenantPayments: NonMemberPaymentReport;
  getAll: CompanyPaginatorDto;
  getAllLeadsForCurrentBroker: Array<LeadDto>;
  getAllLocks: Array<GetLocksDto>;
  getAllOfficeMembersToBeRemindedBeforeLeaveByOneMonth: Array<OfficeDto>;
  getAllUnpaidInvoicesForCurrentMonth: Array<InvoiceDto>;
  getAmenities: Array<AmenityDto>;
  getAmenitiesByType: Array<AmenityDto>;
  getAvailableTypes: AvailableTypesDto;
  getEmailsOfEmailGroup: Array<Scalars['String']['output']>;
  getExternalAmenities: Array<ExternalAmenityDto>;
  getExternalAmenityIcons: Array<AmenityIconDto>;
  getExternalSettings: ListingSettingDto;
  getListingSectionItems: Array<ListingSectionItemsDto>;
  getListingSections: Array<ListingSectionsDto>;
  getMembersCountOfEmailGroup: Scalars['Int']['output'];
  getOfficeFilters: Array<OfficeTypeDto>;
  getOfficeHistoriesByYear: Array<OfficeHistoryDto>;
  getOfficeListingById: OfficeDto;
  getOfficeReport: Array<InvoiceDto>;
  getOfficesListing: OfficePaginator;
  getPublicFormTemplate: PublicFormTemplateDto;
  getUiSettings: GetUiSettingsDto;
  invoice: InvoiceDto;
  invoices: InvoicePaginator;
  isOfficeDuplicated: Scalars['Int']['output'];
  journalEntries: JournalEntryPaginator;
  journalEntry?: Maybe<JournalEntryDto>;
  location: LocationDto;
  locations: LocationPaginator;
  me: UserDtoWithoutToken;
  member: MemberDto;
  members: MemberPaginator;
  office: OfficeDto;
  offices: Array<OfficeDto>;
  outgoingPayment: OutgoingPaymentDto;
  outgoingPayments: OutgoingPaymentPaginator;
  package: PackageDto;
  packages: PackagePaginator;
  payment: PaymentDto;
  paymentBatch?: Maybe<PaymentBatchDto>;
  paymentBatches: PaymentBatchPaginator;
  paymentLogs: PaymentLogPaginator;
  payments: PaymentPaginator;
  pushToken: PushTokenDto;
  scheduled_increase: ScheduledIncreaseDto;
  scheduled_increases: ScheduledIncreasePaginator;
  settings: SettingDto;
  transactionAccount: TransactionAccountDto;
  transactionAccountQuestion: TransactionAccountQuestionDto;
  transactionAccountQuestions: TransactionAcccountPaginator;
  transactionAccounts: TransactionAcccountPaginator;
  vendor?: Maybe<VendorDto>;
  vendors: VendorPaginator;
  zone: ZoneDto;
  zones: ZonePaginator;
};

export type QueryInspectionArgs = {
  data: RemoveInspection;
  include?: InputMaybe<Includeables>;
};

export type QueryInspectionsArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryAccountAccountArgs = {
  data: RemoveAccountingAccount;
};

export type QueryAccountAccountsArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryAccountCodeArgs = {
  data: RemoveReconciliation;
  include?: InputMaybe<Includeables>;
};

export type QueryAccountCodesArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryCheckArgs = {
  data: RemoveCheck;
  include?: InputMaybe<Includeables>;
};

export type QueryChecksArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryContentArgs = {
  data: RemoveContent;
  include?: InputMaybe<Includeables>;
};

export type QueryContentsArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryContractArgs = {
  id: Scalars['Int']['input'];
};

export type QueryContractTemplateArgs = {
  data: RemoveContractTemplate;
};

export type QueryContractTemplatesArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryContractsArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryCountriesArgs = {
  filter?: InputMaybe<Filterable>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryCountryArgs = {
  data: RemoveCountry;
};

export type QueryCurrenciesArgs = {
  filter?: InputMaybe<Filterable>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryCurrencyArgs = {
  data: GetCurrencyById;
};

export type QueryCurrencyByCodeArgs = {
  data: GetCurrencyByCode;
};

export type QueryEmailGroupArgs = {
  data: RemoveEmailGroup;
};

export type QueryEmailGroupConditionArgs = {
  data: RemoveEmailGroupCondition;
};

export type QueryEmailGroupConditionsArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryEmailGroupsArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryEmailSenderArgs = {
  data: RemoveEmailSender;
};

export type QueryEmailSendersArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryEmailTemplateArgs = {
  data: RemoveEmailTemplate;
};

export type QueryEmailTemplatesArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryEmailVariablesArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryExpenseArgs = {
  data: RemoveExpense;
};

export type QueryExpenseTypeArgs = {
  data: RemoveExpenseType;
};

export type QueryExpenseTypesArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryExpensesArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryFindAllAvailableOfficesByLocationsArgs = {
  locations: Array<Scalars['Int']['input']>;
};

export type QueryFindAllLeaseEndingOfficesByLocationsArgs = {
  intervalDays: Scalars['Int']['input'];
  locations: Array<Scalars['Int']['input']>;
};

export type QueryFindAllLocationMembersArgs = {
  filter?: InputMaybe<Filterable>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryFindDaypassPriceLocationAndSettingArgs = {
  data: RemoveLocation;
};

export type QueryFindLocationCountStatisticsArgs = {
  data: RemoveLocation;
};

export type QueryFindLocationPaymentDetailsArgs = {
  data: GetPaymentLocationDetailsForMember;
};

export type QueryFindLocationPercentageStatisticsArgs = {
  data: RemoveLocation;
};

export type QueryFindUserWithLocationHeaderArgs = {
  locationId: Scalars['Float']['input'];
};

export type QueryGetAllArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryGetAllLocksArgs = {
  data?: InputMaybe<GetAllLocks>;
};

export type QueryGetAllOfficeMembersToBeRemindedBeforeLeaveByOneMonthArgs = {
  sendReminder?: Scalars['Boolean']['input'];
};

export type QueryGetAllUnpaidInvoicesForCurrentMonthArgs = {
  notifyAdmins?: Scalars['Boolean']['input'];
  sendReminder?: Scalars['Boolean']['input'];
};

export type QueryGetAmenitiesArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
};

export type QueryGetAmenitiesByTypeArgs = {
  data: GetAmenityByTypeValidation;
};

export type QueryGetEmailsOfEmailGroupArgs = {
  data: RemoveEmailGroup;
};

export type QueryGetMembersCountOfEmailGroupArgs = {
  data: RemoveEmailGroup;
};

export type QueryGetOfficeFiltersArgs = {
  data: GetOfficeFiltersValidation;
};

export type QueryGetOfficeHistoriesByYearArgs = {
  data: GetOfficeHistoriesByOfficeId;
};

export type QueryGetOfficeListingByIdArgs = {
  data: GetOfficeListingByIdValidation;
};

export type QueryGetOfficeReportArgs = {
  data: GetOfficeReport;
};

export type QueryGetOfficesListingArgs = {
  data: GetOfficesListing;
  paginate?: InputMaybe<Paginate>;
  sort?: InputMaybe<Sortable>;
};

export type QueryGetPublicFormTemplateArgs = {
  data: FindFormTemplateValidation;
};

export type QueryInvoiceArgs = {
  id: Scalars['Int']['input'];
  include?: InputMaybe<Includeables>;
};

export type QueryInvoicesArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
  withOffices?: Scalars['Boolean']['input'];
};

export type QueryIsOfficeDuplicatedArgs = {
  data: IsOfficeDuplicated;
};

export type QueryJournalEntriesArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryJournalEntryArgs = {
  data: RemoveJournalEntry;
  include?: InputMaybe<Includeables>;
};

export type QueryLocationArgs = {
  data: RemoveLocation;
  include?: InputMaybe<Includeables>;
};

export type QueryLocationsArgs = {
  addOccupancies?: Scalars['Boolean']['input'];
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryMemberArgs = {
  id: Scalars['Int']['input'];
};

export type QueryMembersArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryOfficeArgs = {
  data: RemoveOffice;
};

export type QueryOfficesArgs = {
  locations: Array<Scalars['Int']['input']>;
};

export type QueryOutgoingPaymentArgs = {
  data: RemoveOutgoingPayment;
};

export type QueryOutgoingPaymentsArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryPackageArgs = {
  data: RemovePackage;
};

export type QueryPackagesArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryPaymentArgs = {
  data: RemovePayment;
  include?: InputMaybe<Includeables>;
};

export type QueryPaymentBatchArgs = {
  data: RemovePaymentBatch;
  include?: InputMaybe<Includeables>;
};

export type QueryPaymentBatchesArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryPaymentLogsArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryPaymentsArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryPushTokenArgs = {
  id: Scalars['Int']['input'];
};

export type QueryScheduled_IncreaseArgs = {
  data: RemoveScheduledIncreaseById;
};

export type QueryScheduled_IncreasesArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryTransactionAccountArgs = {
  data: RemoveTransactionAccount;
};

export type QueryTransactionAccountQuestionArgs = {
  data: RemoveTransactionAccountQuestion;
};

export type QueryTransactionAccountQuestionsArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryTransactionAccountsArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryVendorArgs = {
  data: RemoveVendor;
  include?: InputMaybe<Includeables>;
};

export type QueryVendorsArgs = {
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
};

export type QueryZoneArgs = {
  data: RemoveZone;
};

export type QueryZonesArgs = {
  filter?: InputMaybe<Filterable>;
  paginate?: InputMaybe<Paginate>;
};

export type ReconciliationDto = {
  __typename?: 'ReconciliationDTO';
  difference: Scalars['Float']['output'];
  endBalance: Scalars['Float']['output'];
  endDate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  startBalance: Scalars['Float']['output'];
  startDate: Scalars['String']['output'];
  status: ReconciliationStatuses;
  transactionAccountId?: Maybe<Scalars['Int']['output']>;
};

export type RemoveAccountingAccount = {
  id: Scalars['Int']['input'];
};

export type RemoveCheck = {
  id: Scalars['Int']['input'];
};

export type RemoveContent = {
  id: Scalars['Int']['input'];
};

export type RemoveContractTemplate = {
  id: Scalars['Int']['input'];
};

export type RemoveCountry = {
  id: Scalars['Int']['input'];
};

export type RemoveEmailGroup = {
  id: Scalars['Int']['input'];
};

export type RemoveEmailGroupCondition = {
  id: Scalars['Int']['input'];
};

export type RemoveEmailSender = {
  id: Scalars['Int']['input'];
};

export type RemoveEmailTemplate = {
  id: Scalars['Int']['input'];
};

export type RemoveExpense = {
  id: Scalars['Int']['input'];
};

export type RemoveExpenseType = {
  id: Scalars['Int']['input'];
};

export type RemoveInspection = {
  id: Scalars['Int']['input'];
};

export type RemoveJournalEntry = {
  id: Scalars['Int']['input'];
};

export type RemoveLocation = {
  id: Scalars['Int']['input'];
};

export type RemoveOffice = {
  id: Scalars['Int']['input'];
};

export type RemoveOfficeNote = {
  id: Scalars['Int']['input'];
};

export type RemoveOutgoingPayment = {
  id: Scalars['Int']['input'];
};

export type RemovePackage = {
  id: Scalars['Int']['input'];
};

export type RemovePayment = {
  id: Scalars['Int']['input'];
};

export type RemovePaymentBatch = {
  id: Scalars['Int']['input'];
};

export type RemoveReconciliation = {
  id: Scalars['Int']['input'];
};

export type RemoveScheduledIncreaseById = {
  id: Scalars['Int']['input'];
};

export type RemoveSmartContract = {
  id: Scalars['Int']['input'];
};

export type RemoveTransactionAccount = {
  id: Scalars['Int']['input'];
};

export type RemoveTransactionAccountQuestion = {
  id: Scalars['Int']['input'];
};

export type RemoveVendor = {
  id: Scalars['Int']['input'];
};

export type RemoveZone = {
  id: Scalars['Int']['input'];
};

export type ReportCategoryEntity = {
  __typename?: 'ReportCategoryEntity';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ResetUserPassword = {
  email: Scalars['String']['input'];
};

export enum Role {
  Accountant = 'ACCOUNTANT',
  Admin = 'ADMIN',
  AdminView = 'ADMIN_VIEW',
  Broker = 'BROKER',
  Maintainer = 'MAINTAINER',
  Member = 'MEMBER',
  Owner = 'OWNER',
  SuperAdmin = 'SUPER_ADMIN',
  SuperAdminView = 'SUPER_ADMIN_VIEW',
  TeamMember = 'TEAM_MEMBER',
}

export type ScheduledIncreaseDto = {
  __typename?: 'ScheduledIncreaseDTO';
  applied?: Maybe<Scalars['Int']['output']>;
  applyDate: Scalars['String']['output'];
  autoIncreaseAmount: Scalars['Float']['output'];
  autoIncreaseType: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isAutoIncrease: Scalars['Boolean']['output'];
  newRate: Scalars['String']['output'];
  officeId?: Maybe<Scalars['String']['output']>;
  serviceId?: Maybe<Scalars['String']['output']>;
};

export type ScheduledIncreasePaginator = {
  __typename?: 'ScheduledIncreasePaginator';
  data: Array<ScheduledIncreaseDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type Schema = {
  collection?: InputMaybe<Array<Filter>>;
  type: Where;
};

export type SendMemberAnEmailMessage = {
  attachments?: InputMaybe<Array<Attachment>>;
  content: Scalars['String']['input'];
  fromEmail: Scalars['String']['input'];
  fromName: Scalars['String']['input'];
  replyToEmail?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
  to: Array<Scalars['String']['input']>;
};

export type SettingDto = {
  __typename?: 'SettingDTO';
  addressCity?: Maybe<Scalars['String']['output']>;
  addressCountry?: Maybe<Scalars['String']['output']>;
  addressState?: Maybe<Scalars['String']['output']>;
  addressStreet?: Maybe<Scalars['String']['output']>;
  allowCreditCardFee?: Maybe<Scalars['Int']['output']>;
  allowCustomCurrency?: Maybe<Scalars['Int']['output']>;
  allowExternalDayPass?: Maybe<Scalars['Int']['output']>;
  allowExternalMeetingRooms?: Maybe<Scalars['Int']['output']>;
  allowExternalPackage: Scalars['Int']['output'];
  allowOvercharge?: Maybe<Scalars['String']['output']>;
  arEmail: EmailSenderEntity;
  arEmailId?: Maybe<Scalars['Int']['output']>;
  autoEmailDailyPackages?: Maybe<Scalars['Int']['output']>;
  autoEmailDailyTickets?: Maybe<Scalars['Int']['output']>;
  autoEmailInvoiceCreation?: Maybe<Scalars['Int']['output']>;
  autoIncreaseAmount: Scalars['Float']['output'];
  autoIncreaseSecurityDepositIncrease: Scalars['Float']['output'];
  autoIncreaseType: Scalars['Int']['output'];
  backgroundColor?: Maybe<Scalars['String']['output']>;
  billApprovalType: Scalars['Int']['output'];
  billDevKey?: Maybe<Scalars['String']['output']>;
  billDeviceId?: Maybe<Scalars['String']['output']>;
  billMachineName?: Maybe<Scalars['String']['output']>;
  billMfaId?: Maybe<Scalars['String']['output']>;
  billOrgId?: Maybe<Scalars['String']['output']>;
  billPassword?: Maybe<Scalars['String']['output']>;
  billUserName?: Maybe<Scalars['String']['output']>;
  bookingMaxCancellationPeriod?: Maybe<Scalars['Int']['output']>;
  chargebackFees: Scalars['Float']['output'];
  chargebackFeesEnabled: Scalars['Boolean']['output'];
  chatFeatureEnabled: Scalars['Int']['output'];
  clientManagementToken?: Maybe<Scalars['String']['output']>;
  communityManagerEmail?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  contactusEmail: EmailSenderEntity;
  contactusEmailId?: Maybe<Scalars['Int']['output']>;
  creditCardFee?: Maybe<Scalars['Int']['output']>;
  cronInvoicesAdvanceDays?: Maybe<Scalars['Int']['output']>;
  dateIsUs?: Maybe<Scalars['Int']['output']>;
  daypassPrice?: Maybe<Scalars['Int']['output']>;
  daysBeforeAutoIncreaseNotification: Scalars['Int']['output'];
  defaultAccountingCodeAccount?: Maybe<Scalars['Int']['output']>;
  defaultAccountingCodeTransactionAccounts: Array<TransactionAccountEntity>;
  defaultPaymentAccount?: Maybe<Scalars['Int']['output']>;
  defaultPaymentTransactionAccounts: Array<TransactionAccountEntity>;
  disableMemberPosting?: Maybe<Scalars['Int']['output']>;
  enableAutoIncrease: Scalars['Boolean']['output'];
  enableAutoIncreaseEmailNotification: Scalars['Boolean']['output'];
  enableAutoIncreaseSecurityDeposit: Scalars['Boolean']['output'];
  enableAutobillInvoicesMail?: Maybe<Scalars['Int']['output']>;
  enableBookingNotifications?: Maybe<Scalars['Int']['output']>;
  enableContentNotifications?: Maybe<Scalars['Int']['output']>;
  enableEventNotifications: Scalars['Int']['output'];
  enableInvoiceReminder: Scalars['Int']['output'];
  enablePayrixAutomaticBatching: Scalars['Boolean']['output'];
  enablePostNotifications: Scalars['Int']['output'];
  enableTicketOverdueReminder: Scalars['Int']['output'];
  externalDaypassOption?: Maybe<Scalars['String']['output']>;
  gdprContent?: Maybe<Scalars['String']['output']>;
  greenInvoiceId?: Maybe<Scalars['String']['output']>;
  greenInvoiceSecret?: Maybe<Scalars['String']['output']>;
  hasCrossBooking?: Maybe<Scalars['Int']['output']>;
  hasSecurityDeposit?: Maybe<Scalars['Int']['output']>;
  hideJobBoard?: Maybe<Scalars['Int']['output']>;
  hideMemberList?: Maybe<Scalars['Int']['output']>;
  hubspotApiKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  idToken?: Maybe<Scalars['String']['output']>;
  ignoreGdpr?: Maybe<Scalars['String']['output']>;
  isAmexCreditCardAllowed?: Maybe<Scalars['Int']['output']>;
  isAutoChargeDisabled?: Maybe<Scalars['Int']['output']>;
  isAutoInvoiceDisabled?: Maybe<Scalars['Int']['output']>;
  isTheyard?: Maybe<Scalars['Int']['output']>;
  lateFeeCash?: Maybe<Scalars['Int']['output']>;
  lateFeeDays?: Maybe<Scalars['Int']['output']>;
  lateFeePercentage?: Maybe<Scalars['Int']['output']>;
  lateFeePercentageFrom?: Maybe<Scalars['String']['output']>;
  locksSecret?: Maybe<Scalars['String']['output']>;
  logoDark?: Maybe<Scalars['String']['output']>;
  logoIcon?: Maybe<Scalars['String']['output']>;
  logoJpg?: Maybe<Scalars['String']['output']>;
  logoLight?: Maybe<Scalars['String']['output']>;
  masterRememberToken?: Maybe<Scalars['String']['output']>;
  membersUrl?: Maybe<Scalars['String']['output']>;
  mobileAppAndroidLink?: Maybe<Scalars['Int']['output']>;
  mobileAppIosLink?: Maybe<Scalars['String']['output']>;
  monthsUntilAutoIncrease: Scalars['Int']['output'];
  notifyAdminsWithVacatedUnits: Scalars['Int']['output'];
  payrixTargetTransactionAccount?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  quickBookAccessToken?: Maybe<Scalars['String']['output']>;
  quickBookCheckAccountId?: Maybe<Scalars['String']['output']>;
  quickBookClientId?: Maybe<Scalars['String']['output']>;
  quickBookClientSecret?: Maybe<Scalars['String']['output']>;
  quickBookCompanyId?: Maybe<Scalars['String']['output']>;
  quickBookInvoiceAccountId?: Maybe<Scalars['String']['output']>;
  quickBookOfficeRentRefundAccountId?: Maybe<Scalars['String']['output']>;
  quickBookOtherAccountId?: Maybe<Scalars['String']['output']>;
  quickBookOtherPaymentAccountId?: Maybe<Scalars['String']['output']>;
  quickBookOtherRefundAccountId?: Maybe<Scalars['String']['output']>;
  quickBookPaymentAccountId?: Maybe<Scalars['String']['output']>;
  quickBookRefreshToken?: Maybe<Scalars['String']['output']>;
  quickBookRefreshTokenExpiriesIn?: Maybe<Scalars['String']['output']>;
  quickBookSecurityDepositAccountId?: Maybe<Scalars['String']['output']>;
  quickBookWriteOffAccountId?: Maybe<Scalars['String']['output']>;
  sapCardValidUntil?: Maybe<Scalars['String']['output']>;
  sapCashAccount?: Maybe<Scalars['String']['output']>;
  sapCheckAccount?: Maybe<Scalars['String']['output']>;
  sapCompanyDb?: Maybe<Scalars['String']['output']>;
  sapCounter?: Maybe<Scalars['Int']['output']>;
  sapCreditCard?: Maybe<Scalars['String']['output']>;
  sapCreditCardNumber?: Maybe<Scalars['String']['output']>;
  sapDeduction?: Maybe<Scalars['Int']['output']>;
  sapPassword?: Maybe<Scalars['String']['output']>;
  sapTransferAccount?: Maybe<Scalars['String']['output']>;
  sapUserName?: Maybe<Scalars['String']['output']>;
  sapVoucherNum?: Maybe<Scalars['String']['output']>;
  sendLatePaymentEmailWithoutApplyingFee?: Maybe<Scalars['Int']['output']>;
  sendPaymentRequest?: Maybe<Scalars['Int']['output']>;
  showCredential?: Maybe<Scalars['Int']['output']>;
  showQuickBookMemberNameAs?: Maybe<Scalars['String']['output']>;
  showZendesk?: Maybe<Scalars['Int']['output']>;
  sidemenuColor?: Maybe<Scalars['String']['output']>;
  systemEmail: EmailSenderEntity;
  systemEmailId?: Maybe<Scalars['Int']['output']>;
  terminology?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  topbarColor?: Maybe<Scalars['String']['output']>;
  unreadChatEmailNotification: Scalars['Int']['output'];
  xeroAccessToken?: Maybe<Scalars['String']['output']>;
  xeroClientId?: Maybe<Scalars['String']['output']>;
  xeroClientSecret?: Maybe<Scalars['String']['output']>;
  xeroRefreshToken?: Maybe<Scalars['String']['output']>;
  zmanEmetClientName?: Maybe<Scalars['String']['output']>;
  zmanEmetDepartment?: Maybe<Scalars['String']['output']>;
  zmanEmetDepartmentId?: Maybe<Scalars['String']['output']>;
  zmanEmetUrl?: Maybe<Scalars['String']['output']>;
};

export type Sort = {
  column: Scalars['String']['input'];
  ordering: SortStatus;
};

export enum SortStatus {
  Asc = 'asc',
  Desc = 'desc',
}

export type Sortable = {
  sort?: InputMaybe<Array<Sort>>;
};

export type StaticFilter = {
  address?: InputMaybe<Scalars['String']['input']>;
  amenitiesIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  moveIn?: InputMaybe<Scalars['DateTime']['input']>;
  moveOut?: InputMaybe<Scalars['DateTime']['input']>;
  officeType?: InputMaybe<Array<Scalars['String']['input']>>;
  price?: InputMaybe<Array<Scalars['Int']['input']>>;
  propertyType?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TourDto = {
  __typename?: 'TourDTO';
  applicationId?: Maybe<Scalars['Int']['output']>;
  approved: Scalars['Boolean']['output'];
  approverAdminId?: Maybe<Scalars['Int']['output']>;
  bookableId: Scalars['Int']['output'];
  bookableType: BookableTypes;
  bookedAt: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  locationId: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  officeId?: Maybe<Scalars['Int']['output']>;
  rejectionReason?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  visitedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ToursPaginator = {
  __typename?: 'ToursPaginator';
  data: Array<TourDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type TransactionAcccountPaginator = {
  __typename?: 'TransactionAcccountPaginator';
  data: Array<TransactionAccountDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type TransactionAccountDto = {
  __typename?: 'TransactionAccountDTO';
  accountName?: Maybe<Scalars['String']['output']>;
  accountNumber?: Maybe<Scalars['String']['output']>;
  accountTypeId?: Maybe<Scalars['Int']['output']>;
  amount?: Maybe<Scalars['Int']['output']>;
  bankAddress?: Maybe<Scalars['String']['output']>;
  bankCity?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  bankPhone?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<CurrencyDto>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fromAddress?: Maybe<Scalars['String']['output']>;
  fromCity?: Maybe<Scalars['String']['output']>;
  fromName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orderNumber?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['Int']['output']>;
  routingNumber?: Maybe<Scalars['String']['output']>;
  signature?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subAccountTypeId?: Maybe<Scalars['Int']['output']>;
};

export type TransactionAccountEntity = {
  __typename?: 'TransactionAccountEntity';
  accountName?: Maybe<Scalars['String']['output']>;
  accountNumber?: Maybe<Scalars['String']['output']>;
  accountTypeId?: Maybe<Scalars['Int']['output']>;
  amount?: Maybe<Scalars['Int']['output']>;
  bankAddress?: Maybe<Scalars['String']['output']>;
  bankCity?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  bankPhone?: Maybe<Scalars['String']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fromAddress?: Maybe<Scalars['String']['output']>;
  fromCity?: Maybe<Scalars['String']['output']>;
  fromName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orderNumber?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['Int']['output']>;
  routingNumber?: Maybe<Scalars['String']['output']>;
  signature?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subAccountTypeId?: Maybe<Scalars['Int']['output']>;
};

export type TransactionAccountQuestionDto = {
  __typename?: 'TransactionAccountQuestionDTO';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum Types {
  Box = 'BOX',
  Food = 'FOOD',
  Mail = 'MAIL',
  Other = 'OTHER',
  Package = 'PACKAGE',
}

export type UiSettingsDto = {
  __typename?: 'UiSettingsDTO';
  id: Scalars['ID']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type UnlockDoor = {
  dotId?: InputMaybe<Scalars['Int']['input']>;
  dpuId?: InputMaybe<Scalars['Int']['input']>;
  lineId?: InputMaybe<Scalars['Int']['input']>;
  lockId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

export type UnlockResponseDto = {
  __typename?: 'UnlockResponseDto';
  message: Scalars['String']['output'];
};

export type UpdateAccountingAccount = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCheck = {
  accountingCode?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  bankAccountId?: InputMaybe<Scalars['Float']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  expenseAccountId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Int']['input'];
  membershipId?: InputMaybe<Scalars['Float']['input']>;
  originalAmount?: InputMaybe<Scalars['Float']['input']>;
  payeeName?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateClientSettings = {
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressState?: InputMaybe<Scalars['String']['input']>;
  addressStreet?: InputMaybe<Scalars['String']['input']>;
  allowCreditCardFee?: InputMaybe<Scalars['Float']['input']>;
  allowCustomCurrency?: InputMaybe<Scalars['Float']['input']>;
  allowExternalMeetingRooms?: InputMaybe<Scalars['Float']['input']>;
  allowOvercharge?: InputMaybe<Scalars['Float']['input']>;
  arEmailId?: InputMaybe<Scalars['Float']['input']>;
  autoEmailInvoiceCreation?: InputMaybe<Scalars['Float']['input']>;
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  billDevKey?: InputMaybe<Scalars['String']['input']>;
  billDeviceId?: InputMaybe<Scalars['String']['input']>;
  billMachineName?: InputMaybe<Scalars['String']['input']>;
  billMfaId?: InputMaybe<Scalars['String']['input']>;
  billOrgId?: InputMaybe<Scalars['String']['input']>;
  billPassword?: InputMaybe<Scalars['String']['input']>;
  billUserName?: InputMaybe<Scalars['String']['input']>;
  bookingMaxCancellationPeriod?: InputMaybe<Scalars['Float']['input']>;
  chargebackFees?: InputMaybe<Scalars['Float']['input']>;
  chargebackFeesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  chatFeatureEnabled?: InputMaybe<Scalars['Int']['input']>;
  clientManagementToken?: InputMaybe<Scalars['String']['input']>;
  communityManagerEmail?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  contactusEmailId?: InputMaybe<Scalars['Float']['input']>;
  creditCardFee?: InputMaybe<Scalars['Float']['input']>;
  cronInvoicesAdvanceDays?: InputMaybe<Scalars['Float']['input']>;
  dateIsUs?: InputMaybe<Scalars['Float']['input']>;
  daypassPrice?: InputMaybe<Scalars['Float']['input']>;
  defaultAccountingCodeAccount?: InputMaybe<Scalars['Float']['input']>;
  defaultPaymentAccount?: InputMaybe<Scalars['Float']['input']>;
  disableMemberPosting?: InputMaybe<Scalars['Float']['input']>;
  enableAutobillInvoicesMail?: InputMaybe<Scalars['Float']['input']>;
  enableBookingNotifications?: InputMaybe<Scalars['Float']['input']>;
  enableContentNotifications?: InputMaybe<Scalars['Float']['input']>;
  enableEventNotifications?: InputMaybe<Scalars['Int']['input']>;
  enablePostNotifications?: InputMaybe<Scalars['Int']['input']>;
  gdprContent?: InputMaybe<Scalars['String']['input']>;
  greenInvoiceId?: InputMaybe<Scalars['String']['input']>;
  greenInvoiceSecret?: InputMaybe<Scalars['String']['input']>;
  hasCrossBooking?: InputMaybe<Scalars['Float']['input']>;
  hasSecurityDeposit?: InputMaybe<Scalars['Float']['input']>;
  hideJobBoard?: InputMaybe<Scalars['Float']['input']>;
  hideMemberList?: InputMaybe<Scalars['Float']['input']>;
  hubspotApiKey?: InputMaybe<Scalars['String']['input']>;
  ignoreGdpr?: InputMaybe<Scalars['String']['input']>;
  isAmexCreditCardAllowed?: InputMaybe<Scalars['Float']['input']>;
  isAutoChargeDisabled?: InputMaybe<Scalars['Float']['input']>;
  isAutoInvoiceDisabled?: InputMaybe<Scalars['Float']['input']>;
  isTheyard?: InputMaybe<Scalars['Float']['input']>;
  lateFeeCash?: InputMaybe<Scalars['Float']['input']>;
  lateFeeDays?: InputMaybe<Scalars['Float']['input']>;
  lateFeePercentage?: InputMaybe<Scalars['Float']['input']>;
  lateFeePercentageFrom?: InputMaybe<Scalars['String']['input']>;
  locksSecret?: InputMaybe<Scalars['String']['input']>;
  logoDark?: InputMaybe<Scalars['String']['input']>;
  logoIcon?: InputMaybe<Scalars['String']['input']>;
  logoJpg?: InputMaybe<Scalars['String']['input']>;
  logoLight?: InputMaybe<Scalars['String']['input']>;
  mobileAppAndroidLink?: InputMaybe<Scalars['String']['input']>;
  mobileAppIosLink?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  quickBookAccessToken?: InputMaybe<Scalars['String']['input']>;
  quickBookCheckAccountId?: InputMaybe<Scalars['String']['input']>;
  quickBookClientId?: InputMaybe<Scalars['String']['input']>;
  quickBookClientSecret?: InputMaybe<Scalars['String']['input']>;
  quickBookCompanyId?: InputMaybe<Scalars['String']['input']>;
  quickBookInvoiceAccountId?: InputMaybe<Scalars['String']['input']>;
  quickBookOfficeRentRefundAccountId?: InputMaybe<Scalars['String']['input']>;
  quickBookOtherAccountId?: InputMaybe<Scalars['String']['input']>;
  quickBookOtherPaymentAccountId?: InputMaybe<Scalars['String']['input']>;
  quickBookOtherRefundAccountId?: InputMaybe<Scalars['String']['input']>;
  quickBookPaymentAccountId?: InputMaybe<Scalars['String']['input']>;
  quickBookRefreshToken?: InputMaybe<Scalars['String']['input']>;
  quickBookRefreshTokenExpiriesIn?: InputMaybe<Scalars['String']['input']>;
  quickBookSecurityDepositAccountId?: InputMaybe<Scalars['String']['input']>;
  quickBookWriteOffAccountId?: InputMaybe<Scalars['String']['input']>;
  sapCardValidUntil?: InputMaybe<Scalars['String']['input']>;
  sapCashAccount?: InputMaybe<Scalars['String']['input']>;
  sapCheckAccount?: InputMaybe<Scalars['String']['input']>;
  sapCompanyDb?: InputMaybe<Scalars['String']['input']>;
  sapCounter?: InputMaybe<Scalars['Float']['input']>;
  sapCreditCard?: InputMaybe<Scalars['String']['input']>;
  sapCreditCardNumber?: InputMaybe<Scalars['String']['input']>;
  sapPassword?: InputMaybe<Scalars['String']['input']>;
  sapTransferAccount?: InputMaybe<Scalars['String']['input']>;
  sapUserName?: InputMaybe<Scalars['String']['input']>;
  sapVoucherNum?: InputMaybe<Scalars['String']['input']>;
  sendLatePaymentEmailWithoutApplyingFee?: InputMaybe<Scalars['Float']['input']>;
  sendPaymentRequest?: InputMaybe<Scalars['Float']['input']>;
  showCredential?: InputMaybe<Scalars['Float']['input']>;
  showQuickBookMemberNameAs?: InputMaybe<Scalars['String']['input']>;
  showZendesk?: InputMaybe<Scalars['Float']['input']>;
  sidemenuColor?: InputMaybe<Scalars['String']['input']>;
  systemEmailId?: InputMaybe<Scalars['Float']['input']>;
  terminology?: InputMaybe<Scalars['String']['input']>;
  topbarColor?: InputMaybe<Scalars['String']['input']>;
  unreadChatEmailNotification?: InputMaybe<Scalars['Int']['input']>;
  xeroAccessToken?: InputMaybe<Scalars['String']['input']>;
  xeroClientId?: InputMaybe<Scalars['String']['input']>;
  xeroClientSecret?: InputMaybe<Scalars['String']['input']>;
  xeroRefreshToken?: InputMaybe<Scalars['String']['input']>;
  zmanEmetClientName?: InputMaybe<Scalars['String']['input']>;
  zmanEmetUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContent = {
  address?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  contactMail?: InputMaybe<Scalars['String']['input']>;
  contentBody?: InputMaybe<Scalars['String']['input']>;
  contentBodyImage?: InputMaybe<Scalars['String']['input']>;
  coverPhoto?: InputMaybe<Scalars['String']['input']>;
  dateCreated?: InputMaybe<Scalars['DateTime']['input']>;
  eventDate?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['Upload']['input']>;
  id: Scalars['Int']['input'];
  parent?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ContentTypes>;
};

export type UpdateEmailGroup = {
  conditionsOperator?: InputMaybe<ConditionOperator>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEmailGroupCondition = {
  columnName?: InputMaybe<Scalars['String']['input']>;
  emailGroupId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  operator?: InputMaybe<EmailGroupConditionOperators>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEmailSender = {
  email?: InputMaybe<Scalars['String']['input']>;
  emailListLocations?: InputMaybe<Array<CreateEmailListValidation>>;
  id: Scalars['Int']['input'];
  isAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  isContactus?: InputMaybe<Scalars['Boolean']['input']>;
  isList?: InputMaybe<Scalars['Boolean']['input']>;
  isSystem?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateEmailTemplate = {
  body?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  recipient?: InputMaybe<Scalars['String']['input']>;
  senderId?: InputMaybe<Scalars['Int']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateExpense = {
  adminFee?: InputMaybe<Scalars['Int']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  camId?: InputMaybe<Scalars['Int']['input']>;
  capAmount?: InputMaybe<Scalars['Int']['input']>;
  capped?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expenseAccountId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Int']['input'];
  isCam?: InputMaybe<Scalars['Float']['input']>;
  locationId?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shareRatio?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateExpenseType = {
  accountingAccountsId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInspection = {
  id: Scalars['Int']['input'];
  inspectionDate?: InputMaybe<Scalars['DateTime']['input']>;
  locationId?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['Int']['input']>;
  reminderEmails?: InputMaybe<Scalars['String']['input']>;
  reminderTime?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<InspectionStatus>;
  vendorId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateInvoice = {
  id: Scalars['Int']['input'];
  paymentRequired?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateJournalEntry = {
  date?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  journalNo?: InputMaybe<Scalars['String']['input']>;
  records?: InputMaybe<Array<CreateJournalEntryRecord>>;
};

export type UpdateLocation = {
  accounts?: InputMaybe<Array<CreateLocationTransactionAccount>>;
  addVatAutomatically?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  bankAccountId?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['Int']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  daypassPrice?: InputMaybe<Scalars['Int']['input']>;
  disableAutoCharge?: InputMaybe<Scalars['Int']['input']>;
  ein?: InputMaybe<Scalars['String']['input']>;
  estDate?: InputMaybe<Scalars['String']['input']>;
  floorPlans?: InputMaybe<Scalars['String']['input']>;
  has12Hours?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  kisiGroup?: InputMaybe<Scalars['String']['input']>;
  legalEntity?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  quickBookClassId?: InputMaybe<Scalars['Int']['input']>;
  quickBookClassName?: InputMaybe<Scalars['String']['input']>;
  supportEmails?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  vat?: InputMaybe<Scalars['Int']['input']>;
  zoneId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateMember = {
  additionalEmails?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  bankAccountId?: InputMaybe<Scalars['Int']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  customCurrency?: InputMaybe<Scalars['String']['input']>;
  customCurrencyAuto?: InputMaybe<Scalars['String']['input']>;
  customCurrencyOn?: InputMaybe<Scalars['String']['input']>;
  customCurrencyRate?: InputMaybe<Scalars['String']['input']>;
  dob?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  industryId?: InputMaybe<Scalars['Int']['input']>;
  isExternal?: InputMaybe<Scalars['Int']['input']>;
  keyCardsGranted?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['Int']['input']>;
  locations?: InputMaybe<Array<Scalars['Int']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  paymentLocationid?: InputMaybe<Scalars['Int']['input']>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  sapFederalTaxId?: InputMaybe<Scalars['String']['input']>;
  setupFee?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOffice = {
  accountingCode?: InputMaybe<Scalars['String']['input']>;
  approxValue?: InputMaybe<Scalars['String']['input']>;
  availableDate?: InputMaybe<Scalars['String']['input']>;
  baseRate?: InputMaybe<Scalars['String']['input']>;
  bathrooms?: InputMaybe<Scalars['String']['input']>;
  bedrooms?: InputMaybe<Scalars['String']['input']>;
  curMemberEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  curMemberEndTime?: InputMaybe<Scalars['String']['input']>;
  curMemberLeaseEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  curMemberLeaseStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  curMemberStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  curMemberid?: InputMaybe<Scalars['Int']['input']>;
  curRate?: InputMaybe<Scalars['String']['input']>;
  discountRate?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['String']['input']>;
  formTemplateId?: InputMaybe<Scalars['Int']['input']>;
  futureMemberDiscountRate?: InputMaybe<Scalars['String']['input']>;
  futureMemberEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  futureMemberLeaseEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  futureMemberLeaseStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  futureMemberNumOfTerms?: InputMaybe<Scalars['String']['input']>;
  futureMemberRate?: InputMaybe<Scalars['String']['input']>;
  futureMemberStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  futureMemberid?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  kisiGroup?: InputMaybe<Scalars['String']['input']>;
  leaveReason?: InputMaybe<Scalars['String']['input']>;
  locationid?: InputMaybe<Scalars['Int']['input']>;
  marketingText?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  numOfBWPrints?: InputMaybe<Scalars['String']['input']>;
  numOfColorPrints?: InputMaybe<Scalars['String']['input']>;
  numOfConfRoomHours?: InputMaybe<Scalars['String']['input']>;
  numOfTerms?: InputMaybe<Scalars['String']['input']>;
  parkingNumber?: InputMaybe<Scalars['String']['input']>;
  postBoxNumber?: InputMaybe<Scalars['String']['input']>;
  seats?: InputMaybe<Scalars['String']['input']>;
  sqft?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOutgoingPayment = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  billBankAccountId?: InputMaybe<Scalars['String']['input']>;
  billErrorMessage?: InputMaybe<Scalars['String']['input']>;
  billPaymentId?: InputMaybe<Scalars['String']['input']>;
  checkId?: InputMaybe<Scalars['Int']['input']>;
  expenseAccountId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  invoiceId?: InputMaybe<Scalars['Int']['input']>;
  isBillAdded?: InputMaybe<Scalars['Int']['input']>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  processDate?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePackage = {
  delieveredAt?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryService?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  locationId?: InputMaybe<Scalars['Int']['input']>;
  memberId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  pickedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pickedBy?: InputMaybe<Scalars['String']['input']>;
  serial?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<PackageStatuses>;
  type?: InputMaybe<Types>;
};

export type UpdatePayment = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  deduction?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  incomeAccountId: Scalars['Int']['input'];
  invoiceId?: InputMaybe<Scalars['Float']['input']>;
  memberId?: InputMaybe<Scalars['Float']['input']>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  paymentType?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePaymentBatch = {
  date?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fromTransactionAccountId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  isPaymentProcessorBatch?: InputMaybe<Scalars['Boolean']['input']>;
  paymentCorrelationId?: InputMaybe<Scalars['Int']['input']>;
  paymentIds: Array<Scalars['Int']['input']>;
  paymentProcessorType?: InputMaybe<Scalars['String']['input']>;
  toTransactionAccountId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateReconciliation = {
  batchesIds: Array<Scalars['Int']['input']>;
  checksIds: Array<Scalars['Int']['input']>;
  difference?: InputMaybe<Scalars['Int']['input']>;
  endBalance?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  expenseJournalIds: Array<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  incomeJournalIds: Array<Scalars['Int']['input']>;
  outgoingPaymentsIds: Array<Scalars['Int']['input']>;
  paymentIds: Array<Scalars['Int']['input']>;
  startBalance?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  transactionAccountId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateTransactionAccount = {
  accountName?: InputMaybe<Scalars['String']['input']>;
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  accountTypeId?: InputMaybe<Scalars['Int']['input']>;
  bankAddress?: InputMaybe<Scalars['String']['input']>;
  bankCity?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  bankPhone?: InputMaybe<Scalars['String']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fromAddress?: InputMaybe<Scalars['String']['input']>;
  fromCity?: InputMaybe<Scalars['String']['input']>;
  fromName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  locationId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderNumber?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
  routingNumber?: InputMaybe<Scalars['String']['input']>;
  startingBalance?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  subAccountTypeId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTransactionAccountQuestion = {
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUser = {
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  deleteKisi?: InputMaybe<Scalars['Boolean']['input']>;
  dob?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  isGdprAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  locations?: InputMaybe<Array<Scalars['Int']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  newPassword?: InputMaybe<Scalars['String']['input']>;
  oldPassword?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  privigCode?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Role>;
  updatingMember?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['ID']['input'];
};

export type UpdateUserInfo = {
  aboutMe?: InputMaybe<Scalars['String']['input']>;
  dropBox?: InputMaybe<Scalars['String']['input']>;
  education?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  github?: InputMaybe<Scalars['String']['input']>;
  googlePlus?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  linkedIn?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  skills?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['Int']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVendor = {
  accountNum?: InputMaybe<Scalars['String']['input']>;
  accountingCode?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  bankAddress?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  brokerlicence?: InputMaybe<Scalars['String']['input']>;
  commission?: InputMaybe<Scalars['Int']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  durationType?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  routingNum?: InputMaybe<Scalars['String']['input']>;
  swiftcode?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UserDto = {
  __typename?: 'UserDTO';
  contactNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dob?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageLink?: Maybe<Scalars['String']['output']>;
  isGdprAccepted?: Maybe<Scalars['Int']['output']>;
  isSociallyDeleted?: Maybe<Scalars['Int']['output']>;
  isSystemIntegration?: Maybe<Scalars['Int']['output']>;
  kisiEmail?: Maybe<Scalars['String']['output']>;
  kisiSecret?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  logoLink?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parentUser?: Maybe<Scalars['Int']['output']>;
  permissions?: Maybe<Array<PermissionEntity>>;
  privigCode?: Maybe<Scalars['String']['output']>;
  rememberToken?: Maybe<Scalars['String']['output']>;
  socketId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: Role;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserDtoWithoutToken = {
  __typename?: 'UserDTOWithoutToken';
  contactNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dob?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageLink?: Maybe<Scalars['String']['output']>;
  isGdprAccepted?: Maybe<Scalars['Int']['output']>;
  isSociallyDeleted?: Maybe<Scalars['Int']['output']>;
  isSystemIntegration?: Maybe<Scalars['Int']['output']>;
  kisiEmail?: Maybe<Scalars['String']['output']>;
  kisiSecret?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  logoLink?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  packages: Array<PackageDto>;
  parentUser?: Maybe<Scalars['Int']['output']>;
  permissions?: Maybe<Array<PermissionEntity>>;
  privigCode?: Maybe<Scalars['String']['output']>;
  socketId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  tokens: Array<PushTokenDto>;
  type: Role;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserEntity = {
  __typename?: 'UserEntity';
  contactNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dob?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageLink?: Maybe<Scalars['String']['output']>;
  isGdprAccepted?: Maybe<Scalars['Int']['output']>;
  isSociallyDeleted?: Maybe<Scalars['Int']['output']>;
  isSystemIntegration?: Maybe<Scalars['Int']['output']>;
  kisiEmail?: Maybe<Scalars['String']['output']>;
  kisiSecret?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  logoLink?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parentUser?: Maybe<Scalars['Int']['output']>;
  permissions?: Maybe<Array<PermissionEntity>>;
  privigCode?: Maybe<Scalars['String']['output']>;
  rememberToken?: Maybe<Scalars['String']['output']>;
  socketId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: Role;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserInfoDto = {
  __typename?: 'UserInfoDTO';
  aboutMe?: Maybe<Scalars['String']['output']>;
  coverImageLink?: Maybe<Scalars['String']['output']>;
  dropBox?: Maybe<Scalars['String']['output']>;
  education?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  github?: Maybe<Scalars['String']['output']>;
  googlePlus?: Maybe<Scalars['String']['output']>;
  isHidden?: Maybe<Scalars['Int']['output']>;
  linkedIn?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  skills?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  userId: Scalars['Int']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type VendorDto = {
  __typename?: 'VendorDTO';
  accountNum?: Maybe<Scalars['String']['output']>;
  accountingCode?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  bankAddress?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  billVendorId?: Maybe<Scalars['String']['output']>;
  brokerlicence?: Maybe<Scalars['String']['output']>;
  commission?: Maybe<Scalars['Int']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  durationType: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  office?: Maybe<Scalars['String']['output']>;
  payAuthToken?: Maybe<Scalars['String']['output']>;
  paymentMask?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  routingNum?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  swiftcode?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type VendorEntity = {
  __typename?: 'VendorEntity';
  accountNum?: Maybe<Scalars['String']['output']>;
  accountingCode?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  bankAddress?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  billVendorId?: Maybe<Scalars['String']['output']>;
  brokerlicence?: Maybe<Scalars['String']['output']>;
  commission?: Maybe<Scalars['Int']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  durationType: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  office?: Maybe<Scalars['String']['output']>;
  payAuthToken?: Maybe<Scalars['String']['output']>;
  paymentMask?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  routingNum?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  swiftcode?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type VendorPaginator = {
  __typename?: 'VendorPaginator';
  data: Array<VendorDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type VendorPaymentTypeDto = {
  __typename?: 'VendorPaymentTypeDTO';
  created_at: Scalars['String']['output'];
  payment_type_id: Scalars['Int']['output'];
  updated_at: Scalars['String']['output'];
  vendor_id: Scalars['Int']['output'];
};

export enum Where {
  And = 'and',
  Or = 'or',
}

export type ZoneDto = {
  __typename?: 'ZoneDTO';
  code: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ZonePaginator = {
  __typename?: 'ZonePaginator';
  data: Array<ZoneDto>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export enum ContractStatuses {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Signed = 'SIGNED',
}

export enum ContractTypes {
  Default = 'DEFAULT',
  New = 'NEW',
}

export enum CustomerFieldModels {
  Application = 'APPLICATION',
}

export enum ReconciliationStatuses {
  Closed = 'CLOSED',
  Drafted = 'DRAFTED',
}

export type GetAccountingCodeByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetAccountingCodeByIdQuery = {
  __typename?: 'Query';
  accountCode: { __typename?: 'ReconciliationDTO'; id: string };
};

export type GetAccountCodesQueryVariables = Exact<{
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
}>;

export type GetAccountCodesQuery = {
  __typename?: 'Query';
  accountCodes: {
    __typename?: 'AccountingCodePaginator';
    data: Array<{ __typename?: 'AccountingCodeDTO'; id: string }>;
  };
};

export type Basic_Amenity_AttributesFragment = {
  __typename?: 'AmenityDTO';
  id: string;
  name: string;
  icon?: string | null;
};

export type GetAmenitiesByTypeQueryVariables = Exact<{
  data: GetAmenityByTypeValidation;
}>;

export type GetAmenitiesByTypeQuery = {
  __typename?: 'Query';
  getAmenitiesByType: Array<{ __typename?: 'AmenityDTO'; id: string; name: string; icon?: string | null }>;
};

export type GetHomeAmenitiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetHomeAmenitiesQuery = {
  __typename?: 'Query';
  getExternalAmenities: Array<{
    __typename?: 'ExternalAmenityDto';
    color?: string | null;
    description?: string | null;
    iconId: number;
    id: string;
    title: string;
    icon?: { __typename?: 'AmenityIconDto'; icon?: string | null; id: string } | null;
  }>;
};

export type CreateApplicationMutationVariables = Exact<{
  data: PublicCreateApplicationValidation;
}>;

export type CreateApplicationMutation = {
  __typename?: 'Mutation';
  createApplication?: {
    __typename?: 'ApplicationDTO';
    approved: boolean;
    approverAdminId?: number | null;
    createdAt: any;
    filledAt?: any | null;
    formTemplateId: number;
    id: string;
    leadId?: number | null;
    name: string;
    notes?: string | null;
    officeId: number;
    rejectionReason?: string | null;
    tourId?: number | null;
    updatedAt: any;
  } | null;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: { __typename?: 'UserDTO'; id: string; email: string; name: string; rememberToken?: string | null; type: Role };
};

export type GetFormTemplateByIdQueryVariables = Exact<{
  data: FindFormTemplateValidation;
}>;

export type GetFormTemplateByIdQuery = {
  __typename?: 'Query';
  getPublicFormTemplate: { __typename?: 'PublicFormTemplateDTO'; fields?: any | null; name: string; id: string };
};

export type GetHomeSectionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetHomeSectionsQuery = {
  __typename?: 'Query';
  getListingSections: Array<{
    __typename?: 'ListingSectionsDTO';
    id: string;
    isEnabled?: boolean | null;
    description?: string | null;
    slug: string;
    title?: string | null;
    subTitle?: string | null;
    items: Array<{
      __typename?: 'ListingSectionItemsDTO';
      id: string;
      name?: string | null;
      description?: string | null;
      imageLink?: string | null;
      jobTitle?: string | null;
      links?: Array<{
        __typename?: 'ListingSectionItemLinksDTO';
        id: string;
        label?: string | null;
        link?: string | null;
      }> | null;
    }>;
  }>;
};

export type GetAvailableTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAvailableTypesQuery = {
  __typename?: 'Query';
  getAvailableTypes: { __typename?: 'AvailableTypesDto'; locationTypes: Array<string> };
};

export type GetOfficeFiltersQueryVariables = Exact<{
  data: GetOfficeFiltersValidation;
}>;

export type GetOfficeFiltersQuery = {
  __typename?: 'Query';
  getOfficeFilters: Array<{
    __typename?: 'OfficeTypeDto';
    id: string;
    name: string;
    propertyTypes?: Array<string> | null;
    filters: Array<{
      __typename?: 'OfficeFilterEntity';
      componentType: string;
      fieldName: string;
      id: string;
      label: string;
      maxLength?: number | null;
      isMultiSelect: string;
      minValue?: number | null;
      maxValue?: number | null;
      preview?: string | null;
      secondaryOperator?: Operator | null;
      options?: Array<{ __typename?: 'Option'; label: string; value: string }> | null;
    }>;
    amenities: Array<{ __typename?: 'AmenityDTO'; id: string; icon?: string | null; name: string }>;
  }>;
};

export type GetOfficesListingQueryVariables = Exact<{
  data: GetOfficesListing;
  paginate?: InputMaybe<Paginate>;
  sort?: InputMaybe<Sortable>;
}>;

export type GetOfficesListingQuery = {
  __typename?: 'Query';
  getOfficesListing: {
    __typename?: 'OfficePaginator';
    paginatorInfo?: {
      __typename?: 'PaginatorInfo';
      perPage: number;
      lastItem: number;
      total: number;
      count: number;
      currentPage: number;
      hasMorePages: boolean;
    } | null;
    data: Array<{
      __typename?: 'OfficeDTO';
      id: string;
      locationid?: number | null;
      type?: string | null;
      propertyType?: PropertyType | null;
      baseRate?: string | null;
      formTemplateId?: number | null;
      description?: string | null;
      city?: string | null;
      address?: string | null;
      bathrooms?: string | null;
      bedrooms?: string | null;
      floor?: string | null;
      sqft?: number | null;
      availableDate?: string | null;
      name?: string | null;
      seats?: string | null;
      status?: string | null;
      baseAttributes?: Array<{ __typename?: 'BasicAttributesDTO'; key: string; value: string; icon: string }> | null;
      listingMedia?: Array<{ __typename?: 'ListingMedia'; name: string; url: string }> | null;
      amenities: Array<{ __typename?: 'AmenityDTO'; id: string; icon?: string | null; name: string }>;
      coordinates?: { __typename?: 'GeoJSON'; coordinates: Array<number>; type: string } | null;
      location: {
        __typename?: 'LocationDTO';
        id: string;
        name?: string | null;
        currency?: {
          __typename?: 'CurrencyDTO';
          id: string;
          name: string;
          symbol: string;
          code: string;
          origin?: string | null;
          nameAlt?: string | null;
        } | null;
      };
    }>;
  };
};

export type GetOfficeListingByIdQueryVariables = Exact<{
  data: GetOfficeListingByIdValidation;
}>;

export type GetOfficeListingByIdQuery = {
  __typename?: 'Query';
  getOfficeListingById: {
    __typename?: 'OfficeDTO';
    id: string;
    locationid?: number | null;
    baseRate?: string | null;
    formTemplateId?: number | null;
    description?: string | null;
    city?: string | null;
    address?: string | null;
    bathrooms?: string | null;
    bedrooms?: string | null;
    floor?: string | null;
    sqft?: number | null;
    type?: string | null;
    availableDate?: string | null;
    name?: string | null;
    seats?: string | null;
    status?: string | null;
    listingMedia?: Array<{ __typename?: 'ListingMedia'; name: string; url: string }> | null;
    amenities: Array<{ __typename?: 'AmenityDTO'; id: string; icon?: string | null; name: string }>;
    coordinates?: { __typename?: 'GeoJSON'; coordinates: Array<number>; type: string } | null;
    location: {
      __typename?: 'LocationDTO';
      id: string;
      name?: string | null;
      address?: string | null;
      currency?: {
        __typename?: 'CurrencyDTO';
        id: string;
        name: string;
        symbol: string;
        code: string;
        origin?: string | null;
        nameAlt?: string | null;
      } | null;
    };
    baseAttributes?: Array<{ __typename?: 'BasicAttributesDTO'; key: string; value: string; icon: string }> | null;
  };
};

export type GetPaymentsQueryVariables = Exact<{
  filter?: InputMaybe<Filterable>;
  include?: InputMaybe<Includeables>;
  paginate?: InputMaybe<Paginate>;
}>;

export type GetPaymentsQuery = {
  __typename?: 'Query';
  payments: { __typename?: 'PaymentPaginator'; data: Array<{ __typename?: 'PaymentDTO'; id: string }> };
};

export type GetPaymentQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetPaymentQuery = { __typename?: 'Query'; payment: { __typename?: 'PaymentDTO'; id: string } };

export type GetAllExternalSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllExternalSettingsQuery = {
  __typename?: 'Query';
  getExternalSettings: {
    __typename?: 'ListingSettingDTO';
    mobileAppIosLink?: string | null;
    mobileAppAndroidLink?: string | null;
    allowExternalDayPass: boolean;
    allowExternalMeetingRooms: boolean;
    allowExternalPackage: boolean;
    externalDaypassOption?: string | null;
    isListingEnabled: boolean;
    externalSettings: Array<{
      __typename?: 'ExternalSettingDto';
      id: string;
      slug?: string | null;
      description?: string | null;
      enabled?: boolean | null;
      title?: string | null;
      value?: string | null;
    }>;
  };
};

export type GetUiSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUiSettingsQuery = {
  __typename?: 'Query';
  getUiSettings: {
    __typename?: 'GetUiSettingsDTO';
    uiSettings: Array<{
      __typename?: 'UiSettingsDTO';
      id: string;
      slug?: string | null;
      title?: string | null;
      value?: string | null;
    }>;
  };
};

export type CreateTourMutationVariables = Exact<{
  data: CreateTour;
}>;

export type CreateTourMutation = {
  __typename?: 'Mutation';
  createTour?: { __typename?: 'TourDTO'; id: string } | null;
};

export const Basic_Amenity_AttributesFragmentDoc = gql`
  fragment BASIC_AMENITY_ATTRIBUTES on AmenityDTO {
    id
    name
    icon
  }
`;
export const GetAccountingCodeByIdDocument = gql`
  query GetAccountingCodeByID($id: Int!) {
    accountCode(data: { id: $id }) {
      id
    }
  }
`;

/**
 * __useGetAccountingCodeByIdQuery__
 *
 * To run a query within a React component, call `useGetAccountingCodeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountingCodeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountingCodeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountingCodeByIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetAccountingCodeByIdQuery, GetAccountingCodeByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetAccountingCodeByIdQuery, GetAccountingCodeByIdQueryVariables>(
    GetAccountingCodeByIdDocument,
    options,
  );
}
export function useGetAccountingCodeByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountingCodeByIdQuery, GetAccountingCodeByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetAccountingCodeByIdQuery, GetAccountingCodeByIdQueryVariables>(
    GetAccountingCodeByIdDocument,
    options,
  );
}
export type GetAccountingCodeByIdQueryHookResult = ReturnType<typeof useGetAccountingCodeByIdQuery>;
export type GetAccountingCodeByIdLazyQueryHookResult = ReturnType<typeof useGetAccountingCodeByIdLazyQuery>;
export type GetAccountingCodeByIdQueryResult = Apollo.QueryResult<
  GetAccountingCodeByIdQuery,
  GetAccountingCodeByIdQueryVariables
>;
export const GetAccountCodesDocument = gql`
  query GetAccountCodes($filter: Filterable, $include: Includeables, $paginate: Paginate) {
    accountCodes(filter: $filter, include: $include, paginate: $paginate) {
      data {
        id
      }
    }
  }
`;

/**
 * __useGetAccountCodesQuery__
 *
 * To run a query within a React component, call `useGetAccountCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountCodesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      include: // value for 'include'
 *      paginate: // value for 'paginate'
 *   },
 * });
 */
export function useGetAccountCodesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountCodesQuery, GetAccountCodesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetAccountCodesQuery, GetAccountCodesQueryVariables>(
    GetAccountCodesDocument,
    options,
  );
}
export function useGetAccountCodesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountCodesQuery, GetAccountCodesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetAccountCodesQuery, GetAccountCodesQueryVariables>(
    GetAccountCodesDocument,
    options,
  );
}
export type GetAccountCodesQueryHookResult = ReturnType<typeof useGetAccountCodesQuery>;
export type GetAccountCodesLazyQueryHookResult = ReturnType<typeof useGetAccountCodesLazyQuery>;
export type GetAccountCodesQueryResult = Apollo.QueryResult<GetAccountCodesQuery, GetAccountCodesQueryVariables>;
export const GetAmenitiesByTypeDocument = gql`
  query GetAmenitiesByType($data: GetAmenityByTypeValidation!) {
    getAmenitiesByType(data: $data) {
      ...BASIC_AMENITY_ATTRIBUTES
    }
  }
  ${Basic_Amenity_AttributesFragmentDoc}
`;

/**
 * __useGetAmenitiesByTypeQuery__
 *
 * To run a query within a React component, call `useGetAmenitiesByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAmenitiesByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAmenitiesByTypeQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetAmenitiesByTypeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetAmenitiesByTypeQuery, GetAmenitiesByTypeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetAmenitiesByTypeQuery, GetAmenitiesByTypeQueryVariables>(
    GetAmenitiesByTypeDocument,
    options,
  );
}
export function useGetAmenitiesByTypeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAmenitiesByTypeQuery, GetAmenitiesByTypeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetAmenitiesByTypeQuery, GetAmenitiesByTypeQueryVariables>(
    GetAmenitiesByTypeDocument,
    options,
  );
}
export type GetAmenitiesByTypeQueryHookResult = ReturnType<typeof useGetAmenitiesByTypeQuery>;
export type GetAmenitiesByTypeLazyQueryHookResult = ReturnType<typeof useGetAmenitiesByTypeLazyQuery>;
export type GetAmenitiesByTypeQueryResult = Apollo.QueryResult<
  GetAmenitiesByTypeQuery,
  GetAmenitiesByTypeQueryVariables
>;
export const GetHomeAmenitiesDocument = gql`
  query GetHomeAmenities {
    getExternalAmenities {
      color
      description
      icon {
        icon
        id
      }
      iconId
      id
      title
    }
  }
`;

/**
 * __useGetHomeAmenitiesQuery__
 *
 * To run a query within a React component, call `useGetHomeAmenitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeAmenitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeAmenitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomeAmenitiesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetHomeAmenitiesQuery, GetHomeAmenitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetHomeAmenitiesQuery, GetHomeAmenitiesQueryVariables>(
    GetHomeAmenitiesDocument,
    options,
  );
}
export function useGetHomeAmenitiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHomeAmenitiesQuery, GetHomeAmenitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetHomeAmenitiesQuery, GetHomeAmenitiesQueryVariables>(
    GetHomeAmenitiesDocument,
    options,
  );
}
export type GetHomeAmenitiesQueryHookResult = ReturnType<typeof useGetHomeAmenitiesQuery>;
export type GetHomeAmenitiesLazyQueryHookResult = ReturnType<typeof useGetHomeAmenitiesLazyQuery>;
export type GetHomeAmenitiesQueryResult = Apollo.QueryResult<GetHomeAmenitiesQuery, GetHomeAmenitiesQueryVariables>;
export const CreateApplicationDocument = gql`
  mutation createApplication($data: PublicCreateApplicationValidation!) {
    createApplication(data: $data) {
      approved
      approverAdminId
      createdAt
      filledAt
      formTemplateId
      id
      leadId
      name
      notes
      officeId
      rejectionReason
      tourId
      updatedAt
    }
  }
`;
export type CreateApplicationMutationFn = Apollo.MutationFunction<
  CreateApplicationMutation,
  CreateApplicationMutationVariables
>;

/**
 * __useCreateApplicationMutation__
 *
 * To run a mutation, you first call `useCreateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationMutation, { data, loading, error }] = useCreateApplicationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateApplicationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApplicationMutation, CreateApplicationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateApplicationMutation, CreateApplicationMutationVariables>(
    CreateApplicationDocument,
    options,
  );
}
export type CreateApplicationMutationHookResult = ReturnType<typeof useCreateApplicationMutation>;
export type CreateApplicationMutationResult = Apollo.MutationResult<CreateApplicationMutation>;
export type CreateApplicationMutationOptions = Apollo.BaseMutationOptions<
  CreateApplicationMutation,
  CreateApplicationMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    login(data: { email: $email, password: $password }) {
      id
      email
      name
      rememberToken
      type
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const GetFormTemplateByIdDocument = gql`
  query GetFormTemplateById($data: FindFormTemplateValidation!) {
    getPublicFormTemplate(data: $data) {
      fields
      name
      id
    }
  }
`;

/**
 * __useGetFormTemplateByIdQuery__
 *
 * To run a query within a React component, call `useGetFormTemplateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormTemplateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormTemplateByIdQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetFormTemplateByIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetFormTemplateByIdQuery, GetFormTemplateByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetFormTemplateByIdQuery, GetFormTemplateByIdQueryVariables>(
    GetFormTemplateByIdDocument,
    options,
  );
}
export function useGetFormTemplateByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFormTemplateByIdQuery, GetFormTemplateByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetFormTemplateByIdQuery, GetFormTemplateByIdQueryVariables>(
    GetFormTemplateByIdDocument,
    options,
  );
}
export type GetFormTemplateByIdQueryHookResult = ReturnType<typeof useGetFormTemplateByIdQuery>;
export type GetFormTemplateByIdLazyQueryHookResult = ReturnType<typeof useGetFormTemplateByIdLazyQuery>;
export type GetFormTemplateByIdQueryResult = Apollo.QueryResult<
  GetFormTemplateByIdQuery,
  GetFormTemplateByIdQueryVariables
>;
export const GetHomeSectionsDocument = gql`
  query GetHomeSections {
    getListingSections {
      id
      isEnabled
      description
      slug
      title
      subTitle
      items {
        id
        name
        description
        imageLink
        jobTitle
        links {
          id
          label
          link
        }
      }
    }
  }
`;

/**
 * __useGetHomeSectionsQuery__
 *
 * To run a query within a React component, call `useGetHomeSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeSectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomeSectionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetHomeSectionsQuery, GetHomeSectionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetHomeSectionsQuery, GetHomeSectionsQueryVariables>(
    GetHomeSectionsDocument,
    options,
  );
}
export function useGetHomeSectionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHomeSectionsQuery, GetHomeSectionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetHomeSectionsQuery, GetHomeSectionsQueryVariables>(
    GetHomeSectionsDocument,
    options,
  );
}
export type GetHomeSectionsQueryHookResult = ReturnType<typeof useGetHomeSectionsQuery>;
export type GetHomeSectionsLazyQueryHookResult = ReturnType<typeof useGetHomeSectionsLazyQuery>;
export type GetHomeSectionsQueryResult = Apollo.QueryResult<GetHomeSectionsQuery, GetHomeSectionsQueryVariables>;
export const GetAvailableTypesDocument = gql`
  query getAvailableTypes {
    getAvailableTypes {
      locationTypes
    }
  }
`;

/**
 * __useGetAvailableTypesQuery__
 *
 * To run a query within a React component, call `useGetAvailableTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAvailableTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetAvailableTypesQuery, GetAvailableTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetAvailableTypesQuery, GetAvailableTypesQueryVariables>(
    GetAvailableTypesDocument,
    options,
  );
}
export function useGetAvailableTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAvailableTypesQuery, GetAvailableTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetAvailableTypesQuery, GetAvailableTypesQueryVariables>(
    GetAvailableTypesDocument,
    options,
  );
}
export type GetAvailableTypesQueryHookResult = ReturnType<typeof useGetAvailableTypesQuery>;
export type GetAvailableTypesLazyQueryHookResult = ReturnType<typeof useGetAvailableTypesLazyQuery>;
export type GetAvailableTypesQueryResult = Apollo.QueryResult<GetAvailableTypesQuery, GetAvailableTypesQueryVariables>;
export const GetOfficeFiltersDocument = gql`
  query getOfficeFilters($data: GetOfficeFiltersValidation!) {
    getOfficeFilters(data: $data) {
      filters {
        componentType
        fieldName
        id
        label
        maxLength
        isMultiSelect
        minValue
        maxValue
        preview
        secondaryOperator
        options {
          label
          value
        }
      }
      id
      name
      propertyTypes
      amenities {
        id
        icon
        name
      }
    }
  }
`;

/**
 * __useGetOfficeFiltersQuery__
 *
 * To run a query within a React component, call `useGetOfficeFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficeFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficeFiltersQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetOfficeFiltersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetOfficeFiltersQuery, GetOfficeFiltersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetOfficeFiltersQuery, GetOfficeFiltersQueryVariables>(
    GetOfficeFiltersDocument,
    options,
  );
}
export function useGetOfficeFiltersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOfficeFiltersQuery, GetOfficeFiltersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetOfficeFiltersQuery, GetOfficeFiltersQueryVariables>(
    GetOfficeFiltersDocument,
    options,
  );
}
export type GetOfficeFiltersQueryHookResult = ReturnType<typeof useGetOfficeFiltersQuery>;
export type GetOfficeFiltersLazyQueryHookResult = ReturnType<typeof useGetOfficeFiltersLazyQuery>;
export type GetOfficeFiltersQueryResult = Apollo.QueryResult<GetOfficeFiltersQuery, GetOfficeFiltersQueryVariables>;
export const GetOfficesListingDocument = gql`
  query getOfficesListing($data: GetOfficesListing!, $paginate: Paginate, $sort: Sortable) {
    getOfficesListing(data: $data, paginate: $paginate, sort: $sort) {
      paginatorInfo {
        perPage
        lastItem
        total
        count
        currentPage
        hasMorePages
      }
      data {
        id
        locationid
        type
        propertyType
        baseRate
        formTemplateId
        baseAttributes {
          key
          value
          icon
        }
        listingMedia {
          name
          url
        }
        amenities {
          id
          icon
          name
        }
        description
        coordinates {
          coordinates
          type
        }
        city
        address
        bathrooms
        bedrooms
        floor
        sqft
        availableDate
        location {
          id
          name
          currency {
            id
            name
            symbol
            code
            origin
            nameAlt
          }
        }
        name
        seats
        status
      }
    }
  }
`;

/**
 * __useGetOfficesListingQuery__
 *
 * To run a query within a React component, call `useGetOfficesListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficesListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficesListingQuery({
 *   variables: {
 *      data: // value for 'data'
 *      paginate: // value for 'paginate'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetOfficesListingQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetOfficesListingQuery, GetOfficesListingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetOfficesListingQuery, GetOfficesListingQueryVariables>(
    GetOfficesListingDocument,
    options,
  );
}
export function useGetOfficesListingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOfficesListingQuery, GetOfficesListingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetOfficesListingQuery, GetOfficesListingQueryVariables>(
    GetOfficesListingDocument,
    options,
  );
}
export type GetOfficesListingQueryHookResult = ReturnType<typeof useGetOfficesListingQuery>;
export type GetOfficesListingLazyQueryHookResult = ReturnType<typeof useGetOfficesListingLazyQuery>;
export type GetOfficesListingQueryResult = Apollo.QueryResult<GetOfficesListingQuery, GetOfficesListingQueryVariables>;
export const GetOfficeListingByIdDocument = gql`
  query GetOfficeListingById($data: GetOfficeListingByIdValidation!) {
    getOfficeListingById(data: $data) {
      id
      locationid
      baseRate
      formTemplateId
      listingMedia {
        name
        url
      }
      amenities {
        id
        icon
        name
      }
      description
      coordinates {
        coordinates
        type
      }
      city
      address
      bathrooms
      bedrooms
      floor
      sqft
      type
      availableDate
      location {
        id
        name
        address
        currency {
          id
          name
          symbol
          code
          origin
          nameAlt
        }
      }
      name
      seats
      status
      baseAttributes {
        key
        value
        icon
      }
    }
  }
`;

/**
 * __useGetOfficeListingByIdQuery__
 *
 * To run a query within a React component, call `useGetOfficeListingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficeListingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficeListingByIdQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetOfficeListingByIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetOfficeListingByIdQuery, GetOfficeListingByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetOfficeListingByIdQuery, GetOfficeListingByIdQueryVariables>(
    GetOfficeListingByIdDocument,
    options,
  );
}
export function useGetOfficeListingByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOfficeListingByIdQuery, GetOfficeListingByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetOfficeListingByIdQuery, GetOfficeListingByIdQueryVariables>(
    GetOfficeListingByIdDocument,
    options,
  );
}
export type GetOfficeListingByIdQueryHookResult = ReturnType<typeof useGetOfficeListingByIdQuery>;
export type GetOfficeListingByIdLazyQueryHookResult = ReturnType<typeof useGetOfficeListingByIdLazyQuery>;
export type GetOfficeListingByIdQueryResult = Apollo.QueryResult<
  GetOfficeListingByIdQuery,
  GetOfficeListingByIdQueryVariables
>;
export const GetPaymentsDocument = gql`
  query GetPayments($filter: Filterable, $include: Includeables, $paginate: Paginate) {
    payments(filter: $filter, include: $include, paginate: $paginate) {
      data {
        id
      }
    }
  }
`;

/**
 * __useGetPaymentsQuery__
 *
 * To run a query within a React component, call `useGetPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      include: // value for 'include'
 *      paginate: // value for 'paginate'
 *   },
 * });
 */
export function useGetPaymentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentsQuery, GetPaymentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(GetPaymentsDocument, options);
}
export function useGetPaymentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentsQuery, GetPaymentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(GetPaymentsDocument, options);
}
export type GetPaymentsQueryHookResult = ReturnType<typeof useGetPaymentsQuery>;
export type GetPaymentsLazyQueryHookResult = ReturnType<typeof useGetPaymentsLazyQuery>;
export type GetPaymentsQueryResult = Apollo.QueryResult<GetPaymentsQuery, GetPaymentsQueryVariables>;
export const GetPaymentDocument = gql`
  query GetPayment($id: Int!) {
    payment(data: { id: $id }) {
      id
    }
  }
`;

/**
 * __useGetPaymentQuery__
 *
 * To run a query within a React component, call `useGetPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPaymentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetPaymentQuery, GetPaymentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetPaymentQuery, GetPaymentQueryVariables>(GetPaymentDocument, options);
}
export function useGetPaymentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentQuery, GetPaymentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetPaymentQuery, GetPaymentQueryVariables>(GetPaymentDocument, options);
}
export type GetPaymentQueryHookResult = ReturnType<typeof useGetPaymentQuery>;
export type GetPaymentLazyQueryHookResult = ReturnType<typeof useGetPaymentLazyQuery>;
export type GetPaymentQueryResult = Apollo.QueryResult<GetPaymentQuery, GetPaymentQueryVariables>;
export const GetAllExternalSettingsDocument = gql`
  query GetAllExternalSettings {
    getExternalSettings {
      externalSettings {
        id
        slug
        description
        enabled
        title
        value
      }
      mobileAppIosLink
      mobileAppAndroidLink
      allowExternalDayPass
      allowExternalMeetingRooms
      allowExternalPackage
      externalDaypassOption
      isListingEnabled
    }
  }
`;

/**
 * __useGetAllExternalSettingsQuery__
 *
 * To run a query within a React component, call `useGetAllExternalSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllExternalSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllExternalSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllExternalSettingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllExternalSettingsQuery, GetAllExternalSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetAllExternalSettingsQuery, GetAllExternalSettingsQueryVariables>(
    GetAllExternalSettingsDocument,
    options,
  );
}
export function useGetAllExternalSettingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllExternalSettingsQuery,
    GetAllExternalSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetAllExternalSettingsQuery, GetAllExternalSettingsQueryVariables>(
    GetAllExternalSettingsDocument,
    options,
  );
}
export type GetAllExternalSettingsQueryHookResult = ReturnType<typeof useGetAllExternalSettingsQuery>;
export type GetAllExternalSettingsLazyQueryHookResult = ReturnType<typeof useGetAllExternalSettingsLazyQuery>;
export type GetAllExternalSettingsQueryResult = Apollo.QueryResult<
  GetAllExternalSettingsQuery,
  GetAllExternalSettingsQueryVariables
>;
export const GetUiSettingsDocument = gql`
  query GetUiSettings {
    getUiSettings {
      uiSettings {
        id
        slug
        title
        value
      }
    }
  }
`;

/**
 * __useGetUiSettingsQuery__
 *
 * To run a query within a React component, call `useGetUiSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUiSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUiSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUiSettingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetUiSettingsQuery, GetUiSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetUiSettingsQuery, GetUiSettingsQueryVariables>(GetUiSettingsDocument, options);
}
export function useGetUiSettingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUiSettingsQuery, GetUiSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetUiSettingsQuery, GetUiSettingsQueryVariables>(GetUiSettingsDocument, options);
}
export type GetUiSettingsQueryHookResult = ReturnType<typeof useGetUiSettingsQuery>;
export type GetUiSettingsLazyQueryHookResult = ReturnType<typeof useGetUiSettingsLazyQuery>;
export type GetUiSettingsQueryResult = Apollo.QueryResult<GetUiSettingsQuery, GetUiSettingsQueryVariables>;
export const CreateTourDocument = gql`
  mutation CreateTour($data: CreateTour!) {
    createTour(data: $data) {
      id
    }
  }
`;
export type CreateTourMutationFn = Apollo.MutationFunction<CreateTourMutation, CreateTourMutationVariables>;

/**
 * __useCreateTourMutation__
 *
 * To run a mutation, you first call `useCreateTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTourMutation, { data, loading, error }] = useCreateTourMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTourMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTourMutation, CreateTourMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateTourMutation, CreateTourMutationVariables>(CreateTourDocument, options);
}
export type CreateTourMutationHookResult = ReturnType<typeof useCreateTourMutation>;
export type CreateTourMutationResult = Apollo.MutationResult<CreateTourMutation>;
export type CreateTourMutationOptions = Apollo.BaseMutationOptions<CreateTourMutation, CreateTourMutationVariables>;
