import produce from 'immer';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { UserDto } from '@graphql/hooks-and-types';

type AuthState = {
  state: {
    user: UserDto | null;
    authenticated: boolean;
    client: string | null;
  };
  setAuthenticatedUser: (payload: UserDto) => void;
};
export const useAuth = create(
  persist(
    (set: any) => ({
      state: {
        user: null,
        authenticated: false,
        client: null,
      } as unknown as AuthState['state'],

      setAuthenticatedUser: (payload: { user: UserDto; client: string }) =>
        set(
          produce((draft: AuthState) => {
            draft.state.authenticated = true;
            draft.state.user = payload.user;
            draft.state.client = payload.client;
          }),
        ),
      logoutUser: () =>
        set(
          produce((draft: AuthState) => {
            draft.state.authenticated = false;
            draft.state.user = null;
            draft.state.client = null;
          }),
        ),
    }),
    {
      name: 'auth',
      version: 1,
    },
  ),
);
