var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"fJPTw7_AxHmW--MugsJdA"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const environment = process.env.NODE_ENV || process.env.NEXT_PUBLIC_NODE_ENV || 'development';
const debug = process.env.NODE_ENV !== 'production' || process.env.NEXT_PUBLIC_NODE_ENV !== 'production';
if (SENTRY_DSN && environment !== 'development') {
  Sentry.init({
    sampleRate: 0.4,
    dsn: SENTRY_DSN || 'https://b23fffdc5f5a43089703925ce48698ab@o1191969.ingest.sentry.io/6601261',
    environment,
    release: process.env.npm_package_version,
    debug,
    // tunnel: '/api/tunnel',

    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
      return event;
    },
  });
}
