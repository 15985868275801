import { ConfigProvider, Menu } from 'antd';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

import { useSettingsStore } from '@core/settings/store/settings';

const HeaderMenu = () => {
  const { isDayPassEnabled, isMeetingRoomEnabled, isPackagesEnabled, isUnitsEnabled } = useSettingsStore();
  const router = useRouter();
  const clientName = router.query.clientName as string;

  const memoizedMenuItems = useMemo(() => {
    const menuItems = [{ key: '/home', label: 'Home' }];
    if (isUnitsEnabled) {
      menuItems.push({ key: '/rentals/office-type', label: 'Available Rentals' });
    }
    if (isMeetingRoomEnabled) {
      menuItems.push({
        key: '/booking/meeting-room',
        label: 'Book Meeting Room',
      });
    }
    if (isDayPassEnabled) {
      menuItems.push({
        key: '/booking/day-pass',
        label: 'Buy Day Pass',
      });
    }
    if (isPackagesEnabled) {
      menuItems.push({ key: '/booking/packages', label: 'Buy Credits' });
    }
    return menuItems;
  }, [isDayPassEnabled, isMeetingRoomEnabled, isPackagesEnabled, isUnitsEnabled]);

  const onMenuItemClick = useCallback(
    ({ key }) => {
      router.push(`${key}?clientName=${clientName || 'demo'}`);
    },
    [clientName, router],
  );

  const activeMenuItem = useMemo(() => {
    const active = memoizedMenuItems.find((item) => {
      return router.pathname.includes(item.key);
    });
    return active?.key;
  }, [memoizedMenuItems, router.pathname]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            activeBarHeight: 0,
            itemPaddingInline: 25,
          },
        },
      }}
    >
      <Menu
        style={{ flex: 'auto' }}
        className="h-[78px] min-w-0 flex w-full items-center justify-end flex-row font-sans text-lg font-semibold"
        mode="horizontal"
        items={memoizedMenuItems}
        selectedKeys={[activeMenuItem]}
        onClick={onMenuItemClick}
      />
    </ConfigProvider>
  );
};

export default HeaderMenu;
