import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { Location } from '@core/booking/booking.types';

type LocationsStore = {
  locations: Location[];
  setLocations: (payload: Location[]) => void;
  getLocations: () => Location[];
};

export const useLocationsStore = create(
  persist<LocationsStore>(
    (set, get) => ({
      locations: [],
      setLocations: (payload) => set({ locations: payload }),
      getLocations: () => get().locations,
    }),
    {
      name: 'locations',
      version: 1,
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
